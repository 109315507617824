import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Table, Modal, ListGroup } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { BsCamera, BsEye, BsEyeSlash, BsPlusCircle, BsTrash, BsUpload } from 'react-icons/bs';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import { fetchValoradoDets } from '../../reducers/valoradoDet_slice';
import { addValoradoVen } from '../../reducers/valoradoVen_slice';
import { fetchBancos } from '../../reducers/banco_slice';
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import { fetchAllCajasDiarias } from "../../reducers/caja_slice";
import { formatNumber, nombreApellidosFullPersona } from '../../util/customFormatter';

const ValoradoVenForm = ({ handleClose }) => {
    const dispatch = useDispatch();
    const valoradoDets = useSelector(state => state.valoradoDet.valoradoDets.filter(v => v.estado === 'VIGENTE') || []);
    const afiliados = useSelector(state => state.afiliado.afiliados || []);
    const valorados = useSelector(state => state.valorado.valorados || []);
    
    const bancos = useSelector(state => state.banco.bancos || []);
    const [afiliadoId, setAfiliadoId] = useState('');
    const [afiliadoInfo, setAfiliadoInfo] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredAfiliados, setFilteredAfiliados] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const fecha = moment().tz("America/La_Paz").format('YYYY-MM-DD HH:mm:ss');
    const [detalles, setDetalles] = useState([{ id_vd: '', cantidad: 1, pu: 0, monto: 0, descuento: 0, montopagado: 0, inicial: 0, final_ven: 0, observacion: '', codigo_cuenta: '' }]);
    const [tipoPago, setTipoPago] = useState("Banco");
    const [depositos, setDepositos] = useState([{ id_banco: '', monto: '', fecha_deposito: fecha, docum: '', archivo: null }]);
    const [totalDepositado, setTotalDepositado] = useState(0);
    const userId = useSelector(state => state.sesion.id);
    const xcaja = useSelector(state => state.sesion.caja);
    const xcuentacaja = useSelector(state => state.sesion.cuentacaja);
    const totalPagado = detalles.reduce((sum, detalle) => sum + detalle.montopagado, 0);

    // Estados para la cámara
    const [showCameraModal, setShowCameraModal] = useState(false);
    const [nombreArchivo, setNombreArchivo] = useState('');
    const [imagenPreview, setImagenPreview] = useState(null);
    const videoRef = useRef(null);
    const [depositIndex, setDepositIndex] = useState(null);
    const [imagenVisible, setImagenVisible] = useState([]);

    useEffect(() => {
        dispatch(fetchValoradoDets());
        dispatch(fetchAfiliados());
        dispatch(fetchBancos());
    }, [dispatch]);

    useEffect(() => {
        setTotalDepositado(depositos.reduce((sum, deposito) => sum + parseFloat(deposito.monto || 0), 0));
    }, [depositos]);

    const verificarValoradoDuplicado = (id_vd, currentIndex) => {
        return detalles.some((detalle, index) => detalle.id_vd === id_vd && index !== currentIndex);
    };

    const handleDetailChange = (index, field, value) => {
        const updatedDetails = [...detalles];
        if (field === 'cantidad') {
            value = Math.max(1, value); // No permitir cantidades menores a 1
        }
        if (field === 'id_vd' && verificarValoradoDuplicado(value, index)) {
            Swal.fire('Valorado duplicado', 'Este valorado ya ha sido seleccionado.', 'error');
            return;
        }
        if (field === 'descuento') {
            let descuento=parseFloat(value || 0)
            if(descuento>updatedDetails[index].montopagado) 
                { Swal.fire('Descuento no permitido', 'El descuento no puede ser mayor al monto a pagar', 'error');
                    return;}// No permitir cantidades menores a 1
        }
      if((field ==="id_vd" && value!=="")||field !=="id_vd"){updatedDetails[index][field] = value;}
        if (field === 'id_vd' || field === 'cantidad' || field === 'descuento') {
            const valoradoDet = valoradoDets.find(v => v.id === parseInt(updatedDetails[index].id_vd));
            const valorado = valorados.find(v => v.id === valoradoDet?.id_valorado);
            if (valoradoDet) {
                const inicial = valoradoDet.ultimo_ven > 0 ? valoradoDet.ultimo_ven + 1 : valoradoDet.primero;
                const stockDisponible = valoradoDet.ultimo - valoradoDet.ultimo_ven; // Calcular el stock
                const cantidad = Math.min(updatedDetails[index].cantidad, stockDisponible); // Limitar cantidad al stock disponible
                updatedDetails[index].cantidad = cantidad;
                updatedDetails[index].pu = valoradoDet.precioventa;
                updatedDetails[index].inicial = inicial;
                updatedDetails[index].final_ven = inicial + cantidad - 1; // Actualizar final basado en la cantidad
                updatedDetails[index].nombreValorado = valorado ? valorado.nombre_visible : 'Nombre no disponible';
                updatedDetails[index].monto = cantidad * updatedDetails[index].pu; // Calcular monto basado en la cantidad
                updatedDetails[index].codigo_cuenta = valoradoDet.codigo_cuenta;
                // Calcular monto pagado = (cantidad * pu) - descuento
                updatedDetails[index].montopagado = updatedDetails[index].monto - updatedDetails[index].descuento;
            }
        }
        setDetalles(updatedDetails);
    };

    const handleChangeDeposito = (index, field, value) => {
        const updatedDepositos = [...depositos];
    
        if (field === 'archivo' && value instanceof File) {
            // Crear un nuevo archivo con el nombre sin espacios
            const sanitizedFileName = value.name.replace(/\s+/g, ''); // Eliminar espacios del nombre
            const sanitizedFile = new File([value], sanitizedFileName, { type: value.type });
    
            updatedDepositos[index][field] = sanitizedFile;
        } else {
            updatedDepositos[index][field] = value;
        }
    
        setDepositos(updatedDepositos);
    };
    const handleAddDeposito = () => {
        const lastDeposito = depositos[depositos.length - 1];
        if (!lastDeposito.id_banco || !lastDeposito.monto || !lastDeposito.fecha_deposito || !lastDeposito.docum) {
            Swal.fire('Error', 'Debe completar todos los campos del depósito antes de agregar uno nuevo.', 'error');
            return;
        }
        setDepositos([...depositos, { id_banco: '', monto: '', fecha_deposito: fecha, docum: '', archivo: null }]);
    };

    const handleRemoveDeposito = (index) => {
        if (depositos.length === 1) {
            Swal.fire('Error', 'Debe haber al menos un depósito registrado.', 'error');
            return;
        }
        const updatedDepositos = depositos.filter((_, i) => i !== index);
        setDepositos(updatedDepositos);
    };

    const startCamera = (index) => {
        const randomNum = Math.floor(Math.random() * 1000) + 1;
        setNombreArchivo(`captura-${randomNum}.jpg`);
        setDepositIndex(index);
        setShowCameraModal(true);
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                videoRef.current.play();
            })
            .catch((err) => {
                console.error("Error al acceder a la cámara: ", err);
            });
    };

    const captureImage = () => {
        if (!nombreArchivo) {
            Swal.fire('Error', 'Debe proporcionar un nombre para el archivo antes de capturar la imagen.', 'error');
            return;
        }
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL('image/jpeg');
        const blob = dataURLtoBlob(dataUrl);
        const file = new File([blob], nombreArchivo, { type: 'image/jpeg' });
        if (depositIndex !== null) {
            handleChangeDeposito(depositIndex, 'archivo', file);
        }
        
        setShowCameraModal(false);
    };

    const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    const toggleImageVisibility = (index) => {
        const newVisibility = [...imagenVisible];
        newVisibility[index] = !newVisibility[index];
        setImagenVisible(newVisibility.map((visible, i) => (i === index ? visible : false)));
    
        if (newVisibility[index] && depositos[index].archivo && depositos[index].archivo.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onload = (e) => setImagenPreview(e.target.result);
            reader.readAsDataURL(depositos[index].archivo);
        } else {
            setImagenPreview(null);
        }
    };

    const addDetail = () => {
        const lastDetail = detalles[detalles.length - 1];

        if (lastDetail.id_vd && lastDetail.cantidad > 0 && lastDetail.monto > 0) {
            setDetalles([...detalles, { id_vd: '', cantidad: 1, pu: 0, monto: 0, descuento: 0, montopagado: 0, inicial: 0, final_ven: 0, observacion: '' }]);
        } else {
            Swal.fire('Detalle de venta incompleto', 'Debe completar todos los campos antes de agregar un nuevo detalle.', 'error');
        }
    };

    const removeDetail = (index) => {
        if (detalles.length === 1) return;
        const updatedDetails = detalles.filter((_, i) => i !== index);
        setDetalles(updatedDetails);
    };

    const handleSubmit = async () => {
        console.log("Fecha Ven Val=",fecha)
        if (!afiliadoInfo) {
            Swal.fire('Debe seleccionar un afiliado', 'Debe seleccionar a un afiliado...', 'error');
            return;
        }
    
        // Validaciones para los detalles de la venta
        for (const detalle of detalles) {
            if (!detalle.id_vd) {
                Swal.fire('Falta valorado(s)', 'Debe seleccionar un valorado en todos los detalles.', 'error');
                return;
            }
            if (detalle.descuento > 0 && !detalle.observacion) {
                Swal.fire('Descuento sin observación', 'Si hay un descuento, debe agregar una observación.', 'error');
                return;
            }
        }
    
        // Validación del total de depósitos (si el método de pago es Banco)
        if (tipoPago === "Banco" && totalDepositado !== totalPagado) {
            Swal.fire('Los montos no coinciden', 'El total de los depósitos debe coincidir con el total de la venta.', 'error');
            return;
        }
    
        
        const camposCompletos = depositos.every(
            (deposito) =>
            deposito.id_banco &&
            deposito.monto &&
            deposito.fecha_deposito &&
            deposito.docum &&
            deposito.archivo
        );
       // Validación del total de depósitos (si el método de pago es Banco)
       if (tipoPago === "Banco" &&  !camposCompletos) {
        Swal.fire('Datos incompletos', ' Todos los datos de los depósitos deben ser llenados, incluyendo las Fotos de respaldo correspondientes', 'error');
        return;
    }

        const nroRecibo = "1";
        const recibo = {
            nroRecibo,
            tipo: 'INGRESO',
            fecha,
            monto: totalPagado,
            beneficiario: `Arquitecto: ${afiliadoInfo.persona.apellido1 || ""} ${afiliadoInfo.persona.apellido2 || ""}, ${afiliadoInfo.persona.nombre} Nro. Reg.: ${afiliadoInfo.num_reg}`,
            ci_persona: afiliadoInfo.persona.ci,
            concepto: 'VENTA DE VALORADOS',
            concepto2: "",
            concepto3: "",
            id_caja: xcaja,
            id_usuario: userId,
            pago: tipoPago === "Caja" ? "C" : "B"
        };
    
        const reciboDetalles = detalles.map(detalle => ({
            id_caja: xcaja,
            nroRecibo,
            tipo: 'INGRESO',
            fecha,
            pu: detalle.pu,
            concepto: `${detalle.nombreValorado} del ${detalle.inicial} al ${detalle.final_ven} ${detalle.observacion}`,
            cantidad: detalle.cantidad,
            monto: detalle.monto,
            pagado: detalle.montopagado,
            descuento: detalle.descuento || 0,
            codigo_cuentaC: xcuentacaja,
            codigo_cuentaM: detalle.codigo_cuenta,
            pago: recibo.pago
        }));
    
        const valoradoVenDetalles = detalles.map(detalle => ({
            id_vd: detalle.id_vd,
            cantidad: detalle.cantidad,
            pu: detalle.pu,
            monto: detalle.monto,
            descuento: detalle.descuento || 0,
            montopagado: detalle.montopagado,
            inicial: detalle.inicial,
            final_ven: detalle.final_ven,
            observacion: detalle.observacion,
            id_afiliado: afiliadoId,
            fecha
        }));
    
        // Crear el objeto a enviar al backend
        const dataToSend = {
            recibo,
            reciboDetalles,
            valoradoVenDetalles,
            depositos: tipoPago === "Banco" ? depositos : [] // Incluir depósitos solo si el método de pago es Banco
        };
        const formData = new FormData();
    
        formData.set("recibo",JSON.stringify(recibo))
        formData.set("reciboDetalles",JSON.stringify(reciboDetalles))
        formData.set("valoradoVenDetalles", JSON.stringify(valoradoVenDetalles))
        if( tipoPago === "Banco"){ 
            formData.append('depositos', JSON.stringify(depositos));
            // Agregar los archivos de los depósitos
            depositos.forEach((deposito, index) => {
              if (deposito.archivo) {
                formData.append(`depositos[${index}][archivo]`, deposito.archivo);
              }
            });
        }

        let mensaje=` <div>Beneficiario: <strong>Arq. ${nombreApellidosFullPersona(afiliadoInfo.persona)} </strong></div>
        <br>
        <ul style="list-style: none; padding: 0">`
        reciboDetalles.map(detalle=> mensaje+=`<li> Cant: <strong>${detalle.cantidad}</strong> | ${detalle.concepto}</li>` )
        mensaje+=`</ul><h3> <strong>Total: ${totalPagado} Bs. </strong></h3>
        <div>Tipo de pago: <strong>${tipoPago} </strong></div>`
        Swal.fire({
            title: 'Confirmar venta',
            html: mensaje,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, continuar',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const nuevoRecibo = await dispatch(addValoradoVen(formData)).unwrap();
                    Swal.fire('Éxito', 'Venta registrada correctamente', 'success');
                    const idRecibo = nuevoRecibo.id;
                    handleClose(idRecibo, true);
                } catch (error) {
                    Swal.fire('Error', 'No se pudo registrar la venta', 'error');
                }
            }
        });
    };

   
    return (
        <div style={{ width: '100%', margin: '0 auto' }}>
            {/* Modal para capturar imagen */}
            {showCameraModal && (
                <Modal show={showCameraModal} onHide={() => setShowCameraModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Capturar Imagen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <video ref={videoRef} style={{ width: '100%' }} />
                        <Form.Group className="mt-3">
                            <Form.Label>Nombre del Archivo</Form.Label>
                            <Form.Control
                                type="text"
                                value={nombreArchivo}
                                onChange={(e) => setNombreArchivo(e.target.value)}
                                placeholder="Ingrese el nombre del archivo"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowCameraModal(false)}>
                            Cerrar
                        </Button>
                        <Button variant="primary" onClick={captureImage}>
                            Capturar Imagen
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Buscar Afiliado */}
            <ListGroup>
                <div className='mb-3'>Fecha:
                        <Form.Control type="text" value={fecha} readOnly />
                </div>
                <Form.Label >Buscar Afiliado</Form.Label>
                <Form.Control
                className='mb-5'
                    type="text"
                    placeholder="Buscar por C.I., registro o nombre"
                    value={searchTerm}
                    onChange={(e) => {
                        const term = e.target.value;
                        setSearchTerm(term);
                        if (term.length > 0) {
                            const filtered = afiliados.filter(afiliado => {
                                const { persona } = afiliado;
                                const fullName = `${persona.ci} ${persona.apellido1 || ""} ${persona.apellido2 || ""} ${persona.nombre}`.toLowerCase();
                                return fullName.includes(term.toLowerCase());
                            });
                            setFilteredAfiliados(filtered);
                            setShowDropdown(true);
                        } else {
                            setFilteredAfiliados([]);
                            setShowDropdown(false);
                        }
                    }}
                />
                {showDropdown && (
                    <div className="dropdown-menu show" style={{ width: '100%', maxHeight: '200px', overflowY: 'auto' }}>
                        {filteredAfiliados.map(afiliado => {
                            const persona = afiliado.persona;
                            return (
                                <div
                                    key={afiliado.id}
                                    className="dropdown-item"
                                    onClick={() => {
                                        setAfiliadoId(afiliado.id);
                                        setAfiliadoInfo(afiliado);
                                        setShowDropdown(false);
                                        setSearchTerm(`${persona.apellido1 || ""} ${persona.apellido2 || ""} ${persona.nombre}`);
                                    }}
                                >
                                    {`${persona.apellido1 || ""} ${persona.apellido2 || ""}, ${persona.nombre} - CI: ${persona.ci}`}
                                </div>
                            );
                        })}
                    </div>
                )}
            </ListGroup>

            {/* Detalles de la Venta */}
            <h5>Detalle de la Venta</h5>
            <Table bordered  responsive className='w-100 mb-4'>
                <thead>
                    <tr>
                        <th>Valorado</th>
                        <th>Cantidad</th>
                        <th>P.U. (Bs)</th>
                        <th>Monto (Bs)</th>
                        <th>Descuento</th>
                        <th>Monto a pagar (Bs)</th>
                        <th># Inicial</th>
                        <th># Final</th>
                        <th>Observación</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {detalles.map((detalle, index) => (
                        <tr key={index}>
                            <td>
                                <Form.Control
                                    style={{minWidth:"220px"}}
                                    as="select"
                                    value={detalle.id_vd}
                                    onChange={(e) => handleDetailChange(index, 'id_vd', e.target.value)}
                                >
                                    <option value="">- Sin seleccionar -</option>
                                    {valoradoDets.map(valoradoDet => {
                                        const valorado = valorados.find(v => v.id === valoradoDet.id_valorado);
                                        return (
                                            <option key={valoradoDet.id} value={valoradoDet.id}>
                                                {valorado ? valorado.nombre_visible : 'Nombre no disponible'}
                                            </option>
                                        );
                                    })}
                                </Form.Control>
                            </td>
                            <td>
                                <Form.Control
                                    type="number"
                                    value={detalle.cantidad}
                                    min="1"
                                    onChange={(e) => handleDetailChange(index, 'cantidad', e.target.value)}
                                />
                            </td>
                            <td>
                                <Form.Control 
                                    style={{minWidth:"65px"}} type="text" value={detalle.pu} readOnly />
                            </td>
                            <td>
                                <Form.Control style={{minWidth:"80px"}} type="text" value={detalle.monto} readOnly />
                            </td>
                            <td>
                                <Form.Control style={{minWidth:"70px"}}
                                    type="number"
                                    value={detalle.descuento}
                                    min="0"
                                    onChange={(e) => handleDetailChange(index, 'descuento', e.target.value)}
                                />
                            </td>
                            <td>
                                <Form.Control style={{minWidth:"80px"}} type="text" value={detalle.montopagado} readOnly />
                            </td>
                            <td>
                                <Form.Control style={{minWidth:"90px"}}  type="text" value={detalle.inicial} readOnly />
                            </td>
                            <td>
                                <Form.Control style={{minWidth:"90px"}}  type="text" value={detalle.final_ven} readOnly />
                            </td>
                            <td>
                                <Form.Control
                                    type="text"
                                    value={detalle.observacion}
                                    onChange={(e) => handleDetailChange(index, 'observacion', e.target.value)}
                                />
                            </td>
                            <td style={{minWidth:"10%"}} >
                                {detalles.length > 1 && (
                                    <button className="icon-button" onClick={() => removeDetail(index)}>
                                        <BsTrash />
                                    </button>
                                )}
                                {index === detalles.length - 1 && (
                                    <button className="icon-button" onClick={addDetail}>
                                        <BsPlusCircle />
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Tipo de Pago */}
            <Form.Group>
                <Form.Label className='fw-bold'>Tipo de Pago</Form.Label>
                <Form.Control
                    className='mb-4'
                    as="select"
                    value={tipoPago}
                    onChange={(e) => setTipoPago(e.target.value)}
                >
                    <option value="Caja">Caja</option>
                    <option value="Banco">Banco</option>
                </Form.Control>
            </Form.Group>

            {/* Registro de Depósitos (Solo si el tipo de pago es Banco) */}
            {tipoPago === "Banco" && (
                <>
                    <h5>Registrar Depósitos</h5>
                    <Table bordered responsive>
                        <thead>
                            <tr>
                                <th style={{minWidth:"160px"}}>Banco</th>
                                <th>Monto (Bs)</th>
                                <th>Fecha Depósito</th>
                                <th>N° Ref. Dep.</th>
                                <th>Foto de respaldo</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {depositos.map((deposito, index) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Control
                                            as="select"
                                            value={deposito.id_banco}
                                            onChange={(e) => handleChangeDeposito(index, 'id_banco', e.target.value)}
                                        >
                                            <option value="">- Sin Seleccionar -</option>
                                            {bancos.map(banco => (
                                                <option key={banco.id} value={banco.id}>{banco.sigla}</option>
                                            ))}
                                        </Form.Control>
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="number"
                                            value={deposito.monto}
                                            onChange={(e) => handleChangeDeposito(index, 'monto', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="datetime-local"
                                            value={deposito.fecha_deposito}
                                            onChange={(e) => handleChangeDeposito(index, 'fecha_deposito', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            type="text"
                                            value={deposito.docum}
                                            onChange={(e) => handleChangeDeposito(index, 'docum', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Form.Control
                                                type="file"
                                                accept="image/*, pdf"
                                                onChange={(e) => handleChangeDeposito(index, 'archivo', e.target.files[0])}
                                                style={{ display: 'none' }} // Esto oculta el botón original
                                                id={`file-input-${index}`} // Asignamos un ID único
                                            />    
                                          {/* Creamos un botón personalizado para activar el file input */}
                                          <label htmlFor={`file-input-${index}`} className="icon-button" style={{ cursor: 'pointer', padding:"2px 5px" }}>
                                            <BsUpload /> {/* Usamos un ícono como el de subida */}
                                          </label>
                                            {deposito.archivo && (
                                                <>
                                                     <span style={{ marginLeft: '10px' }}>
                                                    <a
                                                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                                                        href={URL.createObjectURL(deposito.archivo)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        title='Click para ver en una nueva pestaña'
                                                    >
                                                        {deposito.archivo.name}
                                                    </a>
                                                    </span>
                                                    <button
                                                        className="icon-button"
                                                        style={{ marginLeft: '10px' }}
                                                        onClick={() => toggleImageVisibility(index)}
                                                        type="button"
                                                    >
                                                        {imagenVisible[index] ? <BsEyeSlash /> : <BsEye />}
                                                    </button>
                                                </>
                                            )}
                                            <button
                                                className="icon-button"
                                                style={{ marginLeft: '10px' }}
                                                onClick={() => startCamera(index)}
                                                type="button"
                                            >
                                                <BsCamera />
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <button className="icon-button" onClick={handleAddDeposito}>
                                            <BsPlusCircle />
                                        </button>
                                        <button className="icon-button" onClick={() => handleRemoveDeposito(index)}>
                                            <BsTrash />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            )}
        <ListGroup>
            <ListGroup.Item className='mt-4 float end' style={{fontSize:"1.2rem", marginLeft:"auto",width:"40%",fontWeight:"bold"}}>
            Total: {formatNumber(totalPagado.toFixed(2))} Bs.
            </ListGroup.Item>
        </ListGroup>
 <div className="list-container-header mt-5">
 <div className="left-buttons">
 </div>
 <div className="right-buttons">

            {/* Total y Botón de Envío */}
            <Button style={{marginRight:"1rem"}} onClick={()=>handleClose(null, false)} variant='cancel'>Cancelar</Button>
            <Button onClick={handleSubmit}>Registrar Venta</Button>
 </div>

</div>
        </div>
    );
};

export default ValoradoVenForm;