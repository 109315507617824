import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addProyecto, modifyProyecto, fetchProyectos, transferirProyecto, fetchProyectosByUsuario } from '../../reducers/proyecto_slice';
import { fetchAreasProyecto } from '../../reducers/areaProyecto_slice';
import { fetchTiposProyecto } from '../../reducers/tipoProyecto_slice';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { Alert,  Table, Card, Col, Row, Tab, Tabs, Image } from 'react-bootstrap';
import { fetchTipologias } from "../../reducers/tipologia_slice";
import { fetchCategoriasConIndices } from "../../reducers/categoria_slice";
import { formatearDecimal, formatNumber, apellidosNombreFullPersona, formatMayusculaCadaPalabra } from "../../util/customFormatter";
import { fetchHonorariosUnidadRepetida } from "../../reducers/unidad_slice";
import { fetchPersonas } from '../../reducers/persona_slice';
import { fetchCuotasByAfiliado } from '../../reducers/cuota_slice';
import { compressImage, useInputFile, validationFn } from '../../hooks/useInputFile';
import { fechaActualBolivia, fechaActualBoliviaOnly, getEstadoProyecto, getUploadUrl } from '../../util/valueCalculator';
import VistaGeneralProyecto from './VistaGeneralProyecto';
import TabDatosProyecto from './TabDatosProyecto';
import moment from 'moment-timezone';
import { BsCamera } from 'react-icons/bs';
import { fetchBancos } from '../../reducers/banco_slice';
import EpsonPrintModal from '../UI/EpsonPrintModal';
import { fetchAllCajasDiarias, fetchCajasDiariasByUsuario } from '../../reducers/caja_slice';
import { fetchAfiliadoById } from '../../reducers/afiliado_slice';
import TabInvolucrados from './TabInvolucrados';
import TabPago from './TabPago';
import VistaPreviaArchivo from '../UI/VistaPreviaArchivo';
import Draggable from 'react-draggable';


export const positiveOrZero = (value) => {
    const num = value === ""? 0 : parseFloat(value);
    if( !isNaN(num) && num >= 0){
        return num;
    }
    return -1
};
const tiposEspecialesLote =[ {
    nombre:"Licencia de funcionamiento",
    alias:"licencia",
    precio:35
},
{
    nombre:"Cementerio",
    alias:"cementerio",
    precio:5
},
{  nombre:"Plano catastral",
    alias:"plano_catastral",
    precio:35
}]
const initialInvolucrados = {
    id_proyectista: "",
    id_inmueble: "",
    proyectista:null,
    inmueble:null,
    propietarios:[]
}
const initialDatosProy = {
    id_tipo_proyecto: "",
    tipo:null,
    tipo_lote:"", 
    descripcion:"",
    honorarios_total:0,
    comisionCalculada:0,
    conceptoDescuento:"",
    indice_calculo:null,
    comision_cat:0,
    superficie_aprobada:"",
    superficie_total:"",
    tieneunidades:false,
    unidades:[],
    observacion:"",
    comentario1:"",
    id_categoria: "",
    id_area:"",
    id_tipologia: "",
    tipologia:null
}
const initialRespaldos ={
    valorados:[]
}
const initialState = {
   ...initialInvolucrados,
   ...initialDatosProy,
   ...initialRespaldos,
    id_gerente:"",
    estado:"borrador",
}

const verificacionesInitialForm = {
   proyectistaAportes:null
}

const initialDetalles={
    tipo_lote:"", 
    descripcion:"", 
    comisionCalculada:0,
    descuento:0,
    comision_cat:0, 
    honorarios_total:0, 
    indice_calculo:null,
    superficie_total:"",
    tieneunidades:false,
    id_categoria: "",
    id_area:"",
    id_tipologia: "",
    tipologia:null
}
const initialDatosCalculo = {
    id_categoria: "",
    id_area:"",
    id_tipologia: "",
    tipologia:null,
    superficie: "",
    superficie_aprobada:"",
    indice_calculo:null,
    costo_mes:null
}

const initialUnidad = {
    id_categoria: "",
    id_area:"",
    id_tipologia: "",
    tipologia:null,
    descripcion: "",
    superficie: "",
    honorarios:"0",
    honorariosPrimera:"0",
    indice_calculo:null,
    costo_mes:null,
    num_unidades: "1",
}
const initialErrors = {
  }
const initialFormDetalles = {title:"Nuevo Proyecto",
    headerColor:"#254159", 
    buttonText:"Crear" }

const initialStateDepositos = [
    { id_banco: '', 
        monto: '', 
        fecha_deposito: moment().format('YYYY-MM-DD'), 
        docum: '', 
        archivo: null }
    ]   
         
export const porcentaje_comision=0.06;
const FormProyecto = ({ currentProyecto, 
    tipoProceso = "crear", 
    showModal, 
    handleClose,
    handleViewRecibo }) => {


    const dispatch = useDispatch();

    const [datos, setDatos] = useState(initialState);
    const {
        file: fotoPlano,
        tipo: tipoArchivoPlano,
        handleFileChange: handleFotoChange,
        resetInputFile: resetFoto,
        isValid: isFotoValid
    } = useInputFile("doc/image");
     const cajaState = useSelector(state => state.caja || { cajas: [] });
    const cajasDiarias = cajaState.cajasDiarias || [];
    const idCajaDiaria =cajaState.idCajaDiaria;
  
    const [fotoPreview, setFotoPreview] = useState(null);
    const [errors, setErrors] = useState(initialErrors);
    const [verificaciones, setVerificaciones] = useState(verificacionesInitialForm);
    const [error, setError] = useState('');
    const [isModified, setIsModified] = useState(false);
    const [key, setKey] = useState('involucrados');
    const afiliadoUsuario = useSelector(state => state.afiliado.current || null);
    const tipologias = useSelector(state => state.tipologia.tipologias || []);
    const categorias = useSelector(state => state.categoria.categorias || []);
    const honorariosPorUnidadRepetida = useSelector(state => state.unidad.honorariosUnidadRepetida || []);
    const tiposProyecto = useSelector(state => state.tipoProyecto.tipos || []);
    const proyectoState = useSelector(state => state.proyecto.statusSend)
    const [datosCalculoGeneral, setDatosCalculoGeneral] = useState(initialDatosCalculo);
    const [unidadSelected, setUnidadSelected] = useState(initialUnidad);
    const [errorUnidadForm, setErrorUnidadForm] = useState("");
    const [errorDatosCalculoForm, setErrorDatosCalculoForm] = useState("");
    const [editIndex, setEditIndex] = useState(null); // Para manejar la edición
    const [tipoChanged, setTipoChanged] = useState(null);
    const [requiereUnidades, setRequiereUnidades] =useState(false)
    const [requiereCalcular, setRequiereCalcular] =useState(false)
    const [formDetalles, setFormDetalles] = useState(initialFormDetalles);
    const [isCheckedEspecial, setIsCheckedEspecial] = useState(null);
    const [incluyePlanoEsq, setIncluyePlanoEsq] = useState(null);
    
    const [tipoPago, setTipoPago] = useState("B");
    const [depositos, setDepositos] = useState(initialStateDepositos);
    const [paymentSaved, setPaymentSaved] = useState(false);
    const [totalPago, setTotalPago] = useState({
        pagado:0,
        totalConDescuento:0,
        porPagar:0,
        descuento:0,
        pagadoAnteriormente:0
    });
    const [nombreArchivo, setNombreArchivo] = useState(''); 
    const [depositIndex, setDepositIndex] = useState(null); // Nuevo estado para almacenar el índice de la fila actual
    const [showCameraModal, setShowCameraModal] = useState(false); 
    const videoRef = useRef(null); 
    const [botonProformaHabilitado, setBotonProformaHabilitado] = useState(false);
    const [proformaContent, setProformaContent] = useState(null); 
    const [showEpsonPrintModal, setShowEpsonPrintModal] = useState(false); 
    const cuotas = useSelector(state => state.cuota.cuotas);
    const xcaja=useSelector(state => state.sesion.caja);
    const xcuentaC=useSelector(state => state.sesion.cuentacaja);
    const caja = useSelector(state => state.caja);
    const sesion = useSelector(state => state.sesion);
    const { rolActivo, id_afiliado, id:idUser, caja:numCajaUsuario  } = sesion
    const datgeneralList = useSelector((state) => state.datgeneral.datgeneralList);
    const xcuotas = datgeneralList[0]?.cuotas_tolerancia || 0;
    const esResellado = datos?.observacion?.toLowerCase().includes("resellado") && tipoProceso!=="transferir"
 const requestStatus = useSelector(state => state.proyecto.statusSend);
 const fileInputRef = useRef(null);

    // Función para limpiar el campo de archivo
    const clearFileInput = () => {
        // Limpiar el valor del campo de archivo
        if (fileInputRef.current) {
            fileInputRef.current.value = '';  // Resetea el valor del campo
        }
    };


    const cuotasFiltradas = useMemo(() => {
        const hoy = new Date(); // Fecha actual
        const mesActual = hoy.getMonth(); // Mes actual (0-indexado)
        const anioActual = hoy.getFullYear(); // Año actual
    
        // Determinar el último mes evaluado (siempre el mes anterior)
        const ultimoMesEvaluado = mesActual - 1;
    
        const cuotasFilt = cuotas.filter(cuota => {
            const cuotaFecha = new Date(cuota.cuota + "-01"); // Convertir "YYYY-MM" a Date
            return (
                cuota.estado === "no_pagada" &&
                (cuotaFecha.getFullYear() < anioActual ||
                    (cuotaFecha.getFullYear() === anioActual && cuotaFecha.getMonth() < ultimoMesEvaluado))
            );
        });
    
        return cuotasFilt;
    }, [cuotas]);


    useEffect(()=>{
        if(datos.proyectista) {
            if(cuotasFiltradas.length === 0 || cuotasFiltradas.length <= xcuotas) setVerificaciones((prev)=> ({...prev, proyectistaAportes:'sin_pendientes'})) 
            else setVerificaciones((prev)=> ({...prev, proyectistaAportes:'con_pendientes'})) 
        }
    },[cuotasFiltradas])

      useEffect(() => {
        return () => {
          if (showCameraModal && videoRef.current && videoRef.current.srcObject) {
            videoRef.current.srcObject.getTracks().forEach(track => track.stop());
          }
        };
      }, [videoRef, showCameraModal]);

    const esLote = useMemo(()=>{ 
        return datos.tipo && datos.tipo.alias === "lote"
    },[datos.tipo])

    useEffect(() => {
        dispatch(fetchTipologias());
        dispatch(fetchPersonas());
        dispatch(fetchTiposProyecto());
        dispatch(fetchCategoriasConIndices());
        dispatch(fetchHonorariosUnidadRepetida());
        dispatch(fetchAreasProyecto());
        dispatch(fetchBancos());
   // Llamar al thunk para cajas diarias
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchAllCajasDiarias());
   // Llamar al thunk para cajas diarias
    }, [dispatch]);


      useEffect(() => {
        let totalPagado, totalPorPagar, totalPagadoAnteriormente;
        if(tipoProceso==="transferir"){
                totalPorPagar = Math.ceil(parseFloat(datos.comision_cat)/2)
                totalPagadoAnteriormente=0;
                totalPagado = (tipoPago === "B" ? (depositos.reduce((total, deposito) => total + parseFloat(deposito.monto || 0), 0)) 
                                    : totalPorPagar);
            
        }else{  
            const pagosVisados= datos.recibos?.filter(reci => reci.concepto.toLowerCase().includes("visado") ||  reci.concepto.toLowerCase().includes("resellado"))

            totalPagadoAnteriormente= pagosVisados?.reduce((total, recibo) => total + parseFloat(recibo.monto || 0), 0) || 0
            totalPorPagar =  Math.max(0, parseFloat(datos.comision_cat)-totalPagadoAnteriormente)
            totalPagado = (tipoPago === "B" ? (depositos.reduce((total, deposito) => total + parseFloat(deposito.monto || 0), 0)) 
                                : totalPorPagar);
         
    }
        const totalPagadoConDescuento=(datos.conceptoDescuento === "viviendapropia" && tipoProceso!=="transferir")? 0 : totalPorPagar
        const pagadoTotal=(datos.conceptoDescuento === "viviendapropia")? 0 :totalPagado
        const descuentoTotal = (datos.conceptoDescuento === "viviendapropia" && tipoProceso!=="transferir")? totalPorPagar : 0
       
        setDatos({ ...datos, 
                      monto_por_pagar: totalPagadoConDescuento,
                      pagado_previamente: totalPagadoAnteriormente})

        setTotalPago({porPagar: totalPorPagar, 
            totalConDescuento:totalPagadoConDescuento,
            pagado:pagadoTotal,
            descuento:descuentoTotal,
            pagadoAnteriormente:totalPagadoAnteriormente});

        validarCondicionesParaProforma();
      }, [depositos, tipoPago, datos?.comision_cat,datos?.conceptoDescuento,tipoProceso]);
    
     const obtenerUnidadesLista = (unidades, tipologias) => {
        return unidades.map(unidad => {
            const tipologia = tipologias.find(tip => tip.id === unidad.id_tipologia);
            return {
                ...unidad,
                tipologia,
                id_categoria: tipologia.id_categoria,
                id_area: tipologia.id_area,
            };
        });
    };

    useEffect(() => {
        const fetchData = () => {
        if(currentProyecto){
            console.log("currentProyecto en useEffect",!currentProyecto.id)
            const {unidades, valorados, ...proyecto} = currentProyecto
            const unidadesLista = obtenerUnidadesLista(unidades, tipologias)
            let valoradosLista=[];
            let fotoPlano = currentProyecto.foto_plano
             if(!currentProyecto.id || tipoProceso === "transferir"){
                valoradosLista = valorados.map(val => {
                    const {descripcion, id, ValoradoProyecto } = val
                    return {descripcion:descripcion,
                            id_valorado:id, 
                            id_proyecto:"", 
                            nrovalorado:ValoradoProyecto.nrovalorado,
                            activo:true
                            }
                    })
                if(tipoProceso === "duplicar"){
                    setFormDetalles(initialFormDetalles);
                }
                else if(tipoProceso === "transferir")
                    {  fotoPlano=null;
                          setFormDetalles({title:`Transferencia de propietario(s)`, headerColor:"#255957", buttonText:"Confirmar Transferencia de propietario(s)" })
                    }
            } else if(tipoProceso === "editar") {
                if(valorados.length>0)
                  {  valoradosLista = valorados.map(val => {
                        const {ValoradoProyecto, descripcion, id } = val
                        return {descripcion:descripcion,
                                id_valorado:id, 
                                id_proyecto:ValoradoProyecto.id_proyecto, 
                                nrovalorado:ValoradoProyecto.nrovalorado,
                                activo:true
                                }
                    })}
                    else{
                        const tipoProyecto = tiposProyecto.find(tp => `${tp.id}` === `${currentProyecto.id_tipo_proyecto}`);
                        const valoradosActuales = valorados;
                        
                        // Mapear sobre los nuevos valorados, copiando los datos de los existentes si hay coincidencias de id
                        valoradosLista = actualizarValoradosDelProyecto(tipoProyecto, valoradosActuales)
                            
                    }
                    setFormDetalles({title:`Editar proyecto (${getEstadoProyecto(currentProyecto.estado).titulo})`,headerColor:"#255957", buttonText:"Actualizar" })
            } 
            console.log("proyecto.tipo_lote",proyecto.tipo_lote)
         
            let tieneUnidades=currentProyecto.tieneunidades
            let esTipoLoteEspecial = false
            setDatosCalculoGeneral({...initialDatosCalculo,
                id_categoria:currentProyecto.tipologia?.id_categoria,
                id_area:currentProyecto.tipologia?.id_area,
                id_tipologia:currentProyecto.tipologia?.id,
                indice_calculo:currentProyecto.indice_calculo,
                costo_mes:currentProyecto.costo_mes,
                tipologia:currentProyecto.tipologia,
                superficie: currentProyecto.superficie_total
            })
            setUnidadSelected({...initialUnidad,
                id_categoria:currentProyecto.tipologia?.id_categoria,
                id_area:currentProyecto.tipologia?.id_area,
                id_tipologia:currentProyecto.tipologia?.id,
                tipologia:currentProyecto.tipologia,
            })
            if(proyecto.tipo_lote){
                esTipoLoteEspecial=true
            }
            setIncluyePlanoEsq(currentProyecto.incluyeplanoesquematico)
            setIsCheckedEspecial(esTipoLoteEspecial)
            setRequiereUnidades(tieneUnidades)

            if(proyecto.estado === "aprobado"){setKey("vistaGeneral")}
            else  if(proyecto.estado === "pagado"){setKey("Pago")}
                else if(proyecto.estado === "borrador" && tipoProceso!=="transferir")
                    {setKey("datos_proyecto")}
                else {
                    setKey("involucrados")
                }
                if (proyecto.estado === "pagado" && Array.isArray(proyecto.respaldosDep)) {
                    const nowDate=fechaActualBoliviaOnly()
                    const depArray = proyecto.respaldosDep.map((respaldo) => ({
                      id_banco: '', // Asigna el ID del banco o un valor vacío
                      monto: '', // Asigna el monto o un valor vacío
                      fecha_deposito: nowDate, // Asigna la fecha o la actual
                      docum: '', // Asigna el documento o un valor vacío
                      archivo: {name:respaldo.nombre_archivo}, // Asigna el archivo o null
                      tipo:"previo"
                    }));
                    if(depArray.length > 0)
                        { setDepositos(depArray); }// Actualiza el estado con la lista de depósitos
                  }
            setDatos({...proyecto,
                        foto_plano:fotoPlano,
                        id_categoria:currentProyecto.tipologia?.id_categoria,
                        id_area:currentProyecto.tipologia?.id_area,
                        id_tipologia:currentProyecto.tipologia?.id,
                        unidades:unidadesLista,
                        valorados: Array.isArray(valoradosLista) ? valoradosLista : [],
            })
            handleSelect("proyectista", proyecto.proyectista, true)
            if (currentProyecto.foto_plano && tipoProceso==="editar") {
                setFotoPreview(getUploadUrl(currentProyecto.foto_plano));
            } else {
                resetFoto()
            }
        } else {
            resetForm();
            if(rolActivo?.alias==="afiliado"){
                dispatch(fetchAfiliadoById(id_afiliado))
            }
        }

    }

   
    if(showModal){ 
        fetchData();
    }
    else{
        resetFormEdit()
    }
    
    }, [ dispatch, currentProyecto, showModal,rolActivo]);

        useEffect(()=>{
       
            if(afiliadoUsuario){
                handleSelect("proyectista", afiliadoUsuario, true)}
        },[afiliadoUsuario])

   
    const handleRequiereUnidadesChange = async(e) => {
        const requiere = e.target.checked
        let mensaje, result = {isConfirmed:true};
        if(requiere){
            if(!datosCalculoGeneral.id_tipologia || !datosCalculoGeneral.superficie){
                setErrorDatosCalculoForm("Debe elegir la tipologia y definir la superficie del proyecto")
                return null
            }else if(!datosCalculoGeneral.indice_calculo)
                {  setErrorDatosCalculoForm("Debe calcular el IC del proyecto.")
                    setRequiereCalcular(true); 
                return null
                }else if(requiereCalcular){
                    setErrorDatosCalculoForm("Debe hacer click en el botón para aplicar los cambios y recalcular.")
                    return null
                }
        } else { 
            mensaje = "Se eliminarán las unidades registradas." 
        }
        if(!requiere &&datos.unidades.length > 0 && parseFloat(datos.superficie_total) > 0)
        {
            result = await Swal.fire({
                title: '¿Estás seguro?',
                html: mensaje, // Usa HTML aq
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#464646',
                confirmButtonText: 'Sí, continuar',
                cancelButtonText: 'Cancelar'
                })
        }
       
      if(result.isConfirmed){  
        setDatos(prevD =>({...prevD, ...initialDetalles,
            descripcion:prevD.descripcion, tieneunidades:requiere, unidades:[]}))
        if(!requiere){setDatosCalculoGeneral(prevState =>({ ...prevState,
            indice_calculo:unidadSelected.indice_calculo,
            costo_mes:unidadSelected.costo_mes,
            id_tipologia:unidadSelected.id_tipologia,
            id_categoria:unidadSelected.id_categoria,
            id_area:unidadSelected.id_area}));
            setRequiereCalcular(true)
        }
        else{
            setUnidadSelected(prevState => ({ ...prevState, 
            descripcion: "", 
            num_unidades: "1", 
            superficie:"", 
            indice_calculo:datosCalculoGeneral.indice_calculo,
            costo_mes:datosCalculoGeneral.costo_mes,
            id_tipologia:datosCalculoGeneral.id_tipologia,
            id_categoria:datosCalculoGeneral.id_categoria,
            id_area:datosCalculoGeneral.id_area
        }));
        setRequiereCalcular(false)
        }
        setEditIndex(null)
        setErrorUnidadForm("")
        setErrorDatosCalculoForm("")
        setRequiereUnidades(requiere);}
    };


    const handleCheckboxChange = (e) => {
        setDatos(prevD =>({...prevD, ...initialDetalles, superficie_aprobada:"",incluyeplanoesquematico:false}))
        setIsCheckedEspecial(e.target.checked);
    };

    const handleCheckboxPlanoEsquematico = (e) => {
        const isChecked = e.target.checked
        if(parseFloat(datos.comision_cat) > 0) setTipoChanged(true)
        setDatos(prevD =>({...prevD, incluyeplanoesquematico:isChecked}))
        setIncluyePlanoEsq(isChecked);
    };
    const cambioListaIds = (arr1, arr2) => {
        if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
            return false;
        }
        const sortedArr1 = arr1.map(item => item.id).sort()
        const sortedArr2 = arr2.map(item => item.id).sort();
        
        return sortedArr1.join(',') === sortedArr2.join(',');
    };
    const showAdvertencia = async(estado) =>{
        console.log("showAdvertencia", estado)
        const pagoRegistrado = tipoPago==="C" || totalPago.pagado>0
            let mensajeAlerta = ""
            if(tipoProceso === "transferir")
            {
                mensajeAlerta=`Se realizará la <strong>TRANSFERENCIA DE PROPIETARIO(S)</strong> del proyecto visado <strong>N° ${currentProyecto?.proyectoPrevio?.num_visado} </strong> a las siguientes personas:<br> <br>
               <ol>`
                {datos.propietarios.map((propietario) => {
                    return(
                        mensajeAlerta+= `<li key=${propietario.id}>${apellidosNombreFullPersona(propietario)}</li>`
                      )})}
                      mensajeAlerta+="</ol> <br> "
               }
            else{ mensajeAlerta = `El proyecto se guardará como <strong>${getEstadoProyecto(estado).titulo}</strong>`
            }

            if(estado!=="visado" && pagoRegistrado) {
                mensajeAlerta= "No se guardará el pago registrado.<br>"+mensajeAlerta
            }else if(estado === "visado"){
                mensajeAlerta= "Esto es irreversible.<br><br>"+mensajeAlerta;
                mensajeAlerta+= parseFloat(totalPago.porPagar)>0 ? ` y se generará un <strong>recibo</strong> por el pago realizado de <strong>${formatNumber(totalPago.pagado)} Bs</strong><br><br>
                    Tipo de pago: <strong>${tipoPago==="B"?"Banco":"Efectivo"} </strong>`:""
            }
            mensajeAlerta+="."
    
            const result =  await Swal.fire({
                            title: '¿Estás seguro?',
                            html: mensajeAlerta, // Usa HTML aq
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#464646',
                            confirmButtonText: 'Sí, continuar',
                            cancelButtonText: 'Cancelar'
                            })
        return result
    }
    const handleSubmit = async(estado) => {
        const valoradosLlenados=datos.valorados? datos.valorados.every(valorado => valorado.activo? valorado.nrovalorado !== "" : true)
                                :true
        let cajaDiariaError=false;
        let nuevosErrores = {
            id_tipo_proyecto: datos.id_tipo_proyecto === '',
            descripcion: datos.descripcion === '',
            proyectista: datos.id_proyectista === "",
            inmueble: datos.id_inmueble === "",
            propietario: datos.propietarios.length===0,
            recalcularHonorarios: tipoChanged,
            calcularHonorarios: requiereCalcular,
            tipoLote:isCheckedEspecial && !datos.tipo_lote,
            formUnidad:editIndex!==null,
            superficies:tipoProceso!=="transferir" && (datos.estado==="borrador" || datos.estado === "revision") ? (requiereUnidades && parseFloat(datosCalculoGeneral.superficie) !== parseFloat(datos.superficie_total)) : false,
            comentario1: (datos.mostrarAntecedentes && !datos.num_visado) || esResellado? !datos.comentario1 : false
        };
console.log("nuevosErroresss",datos.comentario1,!datos.comentario1)
        if(estado!=="borrador") {
            let supTot = parseFloat(datos.superficie_total)
            nuevosErrores = {...nuevosErrores, 
                    proyectista: nuevosErrores.proyectista || verificaciones.proyectistaAportes === "con_pendientes",
                    unidades: (requiereUnidades && datos.tipo?.alias !=="lote") && datos.unidades.length === 0,
                    plano: fotoPlano && !isFotoValid || ( !fotoPlano && !datos.foto_plano),
                    superficie: !isCheckedEspecial && !esResellado? isNaN(supTot) || supTot === 0 : false,
                }
        }
        if(rolActivo?.alias==="gerente" && ((estado==="aprobado" && datos.estado==="pagado") || (estado==="borrador" && datos.estado==="revision"))) {
            nuevosErrores = {...nuevosErrores, 
                  comentario2: !datos.comentario2,
                }
        }
        if(estado === "visado") {
            const totalAPagar =datos.conceptoDescuento === "viviendapropia"? parseFloat(totalPago.totalConDescuento): parseFloat(totalPago.porPagar)
            const coincidenMontos =totalAPagar === parseFloat(totalPago.pagado)
            cajaDiariaError= !numCajaUsuario && !idCajaDiaria && positiveOrZero(totalPago.pagado)>0
            nuevosErrores = {...nuevosErrores, 
                    totalPago:!coincidenMontos,
                    depositos :totalAPagar>0 &&  tipoPago==="B" && !depositos.every(deposito => deposito.id_banco && deposito.monto && deposito.fecha_deposito && deposito.docum && deposito.archivo),
                    valorados:!valoradosLlenados,
                }
                if(tipoProceso==="transferir"){
                       nuevosErrores = {...nuevosErrores, 
                        noCambioPropietario :  cambioListaIds(currentProyecto?.propietarios, datos.propietarios)
                }
            }
        }
        setErrors(nuevosErrores);
      

        // console.log("antes de enviar", nuevosErrores, isModified)
        // Si todos los campos están completos, enviar el formulario
        if(!cajaDiariaError){
            if(isModified || estado != datos.estado || fotoPlano)
                {  
                    console.log("Nuevos errores", nuevosErrores)
                    if (!Object.values(nuevosErrores).includes(true)) {
                    setError('');
                    const result = await showAdvertencia(estado)
                    if (result.isConfirmed) {
        
                    const formData = new FormData();
                    formData.set("montoPorPagar", totalPago.porPagar)
                    formData.set("descuento", totalPago.descuento)
                    formData.set("montoPorPagarConDesc", totalPago.totalConDescuento)
                    if(estado === "visado"){ 
                        formData.set("montoPagado", totalPago.pagado)
                        formData.set("montoPagadoPreviamente", totalPago.pagadoAnteriormente)
                        formData.set("tipoPago", tipoPago);
                        formData.set("comentario2", "");
                        if( tipoPago === "B"){ 
                            formData.append('depositos', JSON.stringify(depositos));
                            // Agregar los archivos de los depósitos
                            depositos.forEach((deposito, index) => {
                              if (deposito.archivo?.type) {
                                formData.append(`depositos[${index}][archivo]`, deposito.archivo);
                              }
                            });
                        }
                    }
                    if (fotoPlano) {
                        formData.append("foto_plano", fotoPlano);
                    }
                    const {unidades, propietarios, proyectista, 
                        inmueble, tipo, createdAt, 
                        updatedAt, ...proyectoData} = datos
                    const propietariosEnviar = propietarios.map(pro=>pro.id)
        
                   
                    formData.append("propietarios",JSON.stringify(propietariosEnviar));
               
        
                    for (const key in proyectoData) {
                        // console.log("key "+key, proyectoData[key])
                        if (typeof proyectoData[key] === 'object' && proyectoData[key] !== null) {
                            // Convertir arrays u objetos en JSON si es necesario
                            formData.append(key, JSON.stringify(proyectoData[key]));
                        } else if(key!=="descuento"){
                            formData.append(key, proyectoData[key]);
                        }
                            // console.log("formData "+key,formData)
                    }
                    if(tipoProceso === "transferir" && currentProyecto){
                        formData.set("valorados", JSON.stringify(datos.valorados));
                        
                        dispatch(transferirProyecto({ id: currentProyecto.id_proyecto_resellado, data: formData })).then(response => {
                            console.log("REsponse ", response)
                            if ( response.error || response.payload.messages || !response.type.includes("fulfilled")) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: response.error || response.payload.messages[0] || 'Error al transferir el proyecto.',
                                });
                            } else {
                                if(estado === "visado") { 
                                    if(response.payload?.recibos?.length > 0){
                                    console.log("rresponse.payload.recibo ", response.payload.recibos)
                                    
                                    handleViewRecibo({recibos:response.payload.recibos});
                                    dispatch(fetchProyectos())
                                    setPaymentSaved(true);}
                                }   
                                handleClose();
                            }
                        });
        
                    }else{ 

                        
        
                    const unidadesData = unidades.map(unidad=>{
                        const {tipologia, id_categoria, id_area, ...unidadData} = unidad
                        return unidadData
                    })
        
                   
                    formData.set("id_tipologia", datos.id_tipologia? datos.id_tipologia: null);
                    const fechaActual =  fechaActualBolivia() 
                    
                     if(rolActivo?.alias !== "gerente" && estado === "revision") {
                        formData.set("fecha_registro",fechaActual);
                    }
                    if(rolActivo?.alias === "gerente" && (((estado === "visado" && datos.estado !=="aprobado" ) || estado === "aprobado") && datos.estado !=="pagado")) {
                        if(datos.estado === "borrador") {
                            formData.set("fecha_registro",fechaActual);
                        }
                        formData.set("fecha_aprobacion", fechaActual);
                    }
                    if(estado === "visado" ||( estado === "aprobado" && datos.estado !== "pagado")) { 
                        formData.set("comentario2", "");
                    }
                    formData.set("estado", estado);
                  // Agregar datos específicos si es necesario
                    if (Array.isArray(unidadesData)) {
                        if(unidadesData.length === 1 && !unidadesData[0].descripcion)
                        { unidadesData[0].descripcion = proyectoData.descripcion }
                        formData.append("unidades", JSON.stringify(unidadesData));
                    }
                    formData.set("descripcion", datos.descripcion.toUpperCase());
                if (currentProyecto && tipoProceso==="editar") {
                        dispatch(modifyProyecto({ id: currentProyecto.id, proyecto: formData })).then(response => {
                            if (response.error || response.payload.messages) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: response.error || response.payload.messages[0] || 'Error al actualizar el proyecto.',
                                });
                            } else {
                                if(estado === "visado") { 
                                  
                                if(response.payload?.recibos?.length > 0){
                                
                                    handleViewRecibo({recibos: response.payload.recibos});
                                    setPaymentSaved(true);}
                                      if(rolActivo){
                                        console.log("rolActivo modify ", rolActivo)
                                          if(rolActivo?.alias === "afiliado") {dispatch(fetchProyectosByUsuario()) }
                                        else {dispatch(fetchProyectos()) }
                                      }
                                }   
                                handleClose();
                            }
                        });
                    } else {
                        dispatch(addProyecto(formData)).then(response => {
                            console.log("response",response)
                            if(response.payload?.error || response.payload?.messages){
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: response.payload.error || response.payload.messages[0] || 'Error al crear el proyecto.',
                                });
                               }
                              else {
                                if(estado === "visado") { 
                                    console.log("rresponse.payload.recibo ", response.payload.recibos)
                                    if(response.payload?.recibos?.length > 0){
                                    
                                        handleViewRecibo({recibos:response.payload.recibos});
                                    }
                                } else {
                                Swal.fire('Creado', 'Se ha creado correctamente el proyecto.', 'success');   
                                }handleClose();
                       
                        }}).catch((error) => {
                            console.log("error",error,"requestStatus",requestStatus)
                            Swal.fire('Error', error?.error || 'Ocurrió un error al crear el proyecto.', 'error');
                        });;
                    }
                }     }
            } else {
                // Acción cancelada
                
                console.log('nuevosErrores',nuevosErrores);
              }
            }else {
                    handleClose();
                }
        }else{
            Swal.fire('Caja Diaria Cerrada', 'Debe abrir la caja diaria del día de hoy para realizar pagos.', 'error');
        }
       
    
    }
    ;

      const validarCondicionesParaProforma = () => {
        const montoExistente = totalPago.porPagar > 0 && datos.tipo && !requiereCalcular && !tipoChanged
        setBotonProformaHabilitado(montoExistente && !paymentSaved); 
      };
    
    const resetForm = () => {
        setDatos(initialState);
        setRequiereUnidades(false)
        setUnidadSelected(initialUnidad);
        setDatosCalculoGeneral(initialDatosCalculo);
        setIsModified(false);
        setTipoChanged(false)
        setIsCheckedEspecial(false)
        setFormDetalles(initialFormDetalles);
        setVerificaciones(verificacionesInitialForm)
        resetFoto()
        setKey("involucrados")
        resetFormEdit()
        setIncluyePlanoEsq(false)
    };
    const resetFormEdit = () => {
        setEditIndex(null)
        setRequiereCalcular(false)
        resetErrors()
        resetFoto()
        setFotoPreview(null);
        setTipoPago("B")
        setDepositos(initialStateDepositos)
        setPaymentSaved(false)
    };
    const resetErrors = () => {
        setError('');
        setVerificaciones(verificacionesInitialForm)
        setErrors({})
        setErrorUnidadForm("")
        setErrorDatosCalculoForm("")
    };
    
  const handleProforma = () => {
     const totalGeneral = parseFloat(totalPago.totalConDescuento)

     const calculoPorFormula = !esLote || datos.superficie_total>0 ? [ `Superficie por aprobar: ${formatNumber(datos.superficie_total)} m2`,
        `Honorarios calculados: ${formatNumber(datos.honorarios_total)} Bs.`]:[]

        const titulo = esResellado  ?['PROFORMA PAGO DE RESELLADO']
        : tipoProceso==="transferir"? ['PROFORMA PAGO TRANSFERENCIA DE PROPIETARIO(S)']:
        ['PROFORMA PAGO DE VISADO']
        let unidadesData = datos.unidades.flatMap(u => {
                    let unidadesGroup = [];
                    // Crear un arreglo con el número de unidades
                    for (let i = 1; i <= u.num_unidades; i++) {
                        console.log(i); // Este log imprimirá los números del 1 hasta num_unidades
                        const honorario = honorariosPorUnidadRepetida.find(h => h.num_unidad_desde <= i && h.num_unidad_hasta >= i);
                        const subtotal = parseFloat((u.honorariosPrimera * (honorario.porcentaje / 100)).toFixed(2));
                        const subVisacion = parseFloat((subtotal * porcentaje_comision).toFixed(2));

                        let conceptoDetalle = `     ${u.descripcion}`;
                        if (u.num_unidades > 1) {
                            conceptoDetalle += ` N°${i} rep.`;
                        }
                        conceptoDetalle += ` / ${formatNumber(u.superficie)} m2  / ${formatNumber(u.costo_mes)}(CM)  / ${formatNumber(subVisacion)} Bs.`;

                        // Aquí simplemente agregamos la cadena conceptoDetalle al array en lugar de un objeto
                        unidadesGroup.push(conceptoDetalle);
                     }
                // Regresamos el arreglo de strings para cada unidad
                return unidadesGroup; // Esto es ahora un arreglo de strings
            });

            const descuento = esResellado  ?[`Pagado previamente: ${totalPago.pagadoAnteriormente} Bs.`]
            : datos.conceptoDescuento? [`Tipo descuento: ${datos.conceptoDescuento}`]:
            ['']
console.log("unidadesData", unidadesData);
    if(unidadesData.length>0) unidadesData.unshift("Unidades:")
    const detalle = datos.proyectista? [
      ...titulo,
      '=======================',
      `Afiliado: ${apellidosNombreFullPersona(datos.proyectista.persona)}`,
      `C.I.: ${datos.proyectista.persona.ci}`,
      `Nro. Reg.: ${datos.proyectista.num_reg}`,
      '--------------------------------------',
      `Tipo de proyecto: ${datos.tipo?.nombre || "Sin definir"}`,
      `Carácter: ${datos.descripcion || "Sin definir"}`,
     ...calculoPorFormula,
     ...unidadesData,
      `Visación: ${formatNumber(datos.comision_cat)} Bs.`,
      ...descuento,
      '--------------------------------------',
      `TOTAL A PAGAR = ${formatNumber(totalGeneral)} Bs.`,
    ]: ['PROFORMA PAGO DE VISADO'];

    setProformaContent({ detalle });
    setShowEpsonPrintModal(true); 
  };


const captureImage = () => {
    if (!nombreArchivo) {
      Swal.fire('Error', 'Debe proporcionar un nombre para el archivo antes de capturar la imagen.', 'error');
      return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL('image/jpeg');
    let blob;

    try {
        blob = dataURLtoBlob(dataUrl);
        console.log("Blob creado:", blob);
    } catch (error) {
        console.error("Error al convertir el dataURL a Blob:", error.message);
    }
    
    if (blob) {
        // Solo crear el archivo si el Blob fue creado con éxito
        const file = new File([blob], nombreArchivo, { type: 'image/jpeg' });
        if (depositIndex !== null) {
            handleFileDepositoChange(depositIndex, file); // Actualiza la fila correcta
          }else {
              handlePlanoChange(file, true)
          }
      
              setShowCameraModal(false);
    } else {
        console.error("No se pudo tomar la foto.");
    }
    
   
    };

    const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };
    const handleChangeTipoPago=(e)=>{
       if(esLote){ 
        const nuevoTipoPago = e.target? (e.target.checked? "C": "B") : e 
      
        setTipoPago((prevTipo) =>{
            setDepositos((prevDep => prevTipo !== nuevoTipoPago? initialStateDepositos : prevDep))
            return nuevoTipoPago
        })
     }
    }

    const handleChangeDescuento=(name, value, arqui, listaPropietarios )=>{
        let errorViviendaPropia=false
        let conceptoDescuento=""
        let descuento=0
        console.log("en handleChangeDescu",name, value, arqui, listaPropietarios)
        const proyectista = arqui? arqui : datos.proyectista
        const propietarios = listaPropietarios? listaPropietarios : datos.propietarios
        if( name === "viviendapropia")
        { if(value)
           {   if(propietarios.some(propietario => 
                        propietario.id === proyectista.persona?.id
                ))
                {
                    conceptoDescuento = name
                    descuento = datos.comision_cat;
                    errorViviendaPropia = false
           
                } else{ errorViviendaPropia = true }
            }
            else{ errorViviendaPropia = false }
        }
        return {errorViviendaPropia, conceptoDescuento, descuento}
   
    }

  const handleAddDeposito = async() => {
    const lastDeposito = depositos[depositos.length - 1];
    if (lastDeposito.id_banco && lastDeposito.monto && lastDeposito.fecha_deposito && lastDeposito.docum && lastDeposito.archivo) {
      setDepositos(prevDepositos=> ([...prevDepositos, { id_banco: '', monto: '', fecha_deposito: moment().format('YYYY-MM-DD'), docum: '', archivo: null }]));
    } else {
      await Swal.fire('Error', 'Debe completar todos los campos del depósito antes de agregar uno nuevo.', 'error');
    }
  };

  const handleRemoveDeposito = (index) => {
    setDepositos(prevDepositos => prevDepositos.filter((_, i) => i !== index))
  };

  const handleChangeDeposito = async(index, field, value) => {
    const newDepositos = [...depositos];
    if (field === 'fecha_deposito' && moment(value).isAfter(moment())) {
      await Swal.fire('Error', 'La fecha de depósito no puede ser posterior a la fecha actual.', 'error');
      return;
    }
    newDepositos[index][field] = value;
    setDepositos(newDepositos);
  };
  const handleFileDepositoChange = async (index, file) => {
    const newDepositos = [...depositos];
    try {
        // Comprimir la imagen
          const compressedFile = await compressImage(file);
          newDepositos[index].archivo = compressedFile;
          newDepositos[index].tipo = "nuevo";
        } catch (error) {
          console.error("Error al comprimir la imagen:", error);
        }


    setDepositos(newDepositos);
  
  };

  
  const startCamera = (index) => {
    console.log("startCamera",datos)
    const iniciales = datos.proyectista? `${datos.proyectista.persona.ci}`:"sinproyectista";
    const randomNum = Math.floor(Math.random() * 1000) + 1;

    console.log("iniciales",iniciales)
    console.log("randomNum",randomNum, index)
    setNombreArchivo(`${iniciales}-${randomNum}.jpg`);
    setDepositIndex(index); // Guarda el índice actua
    setShowCameraModal(true);

    navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    }).catch((err) => {
      console.error("Error al acceder a la cámara: ", err);
    });
  };

  const handleTipoLoteChange = (name, value) => {
        if(esLote)
        {   
            if( name==="tipo_lote") {
            const tipoLote = tiposEspecialesLote.find(t => t.alias === value)
            const precioLote = tipoLote?.precio || null;
            setDatos((prevDatos) => ({ ...prevDatos, 
                tipo_lote: value, 
                superficie_total:0,
                superficie_aprobada:0,
                honorarios_total:0,
                descripcion:tipoLote.nombre,
                comision_cat:precioLote}));
            }else 
            if( name==="superficie_total"){
                const superficieTotal = positiveOrZero(value)
               if(superficieTotal >= 0) { 
             
                const selectedCategoria = categorias.find(cat => `${cat.num}` === `6`);
                const indiceCalculo = obtenerIndiceCalculo(superficieTotal, selectedCategoria);
               
                const {honorariosPrimera, honorariosUnidad, comisionPorUnidad,descuentoUnidad} = calculoHonorariosUnidad(indiceCalculo, selectedCategoria, 1 , superficieTotal, -0.5);

               let comision = redondear(comisionPorUnidad)
               setDatos((prevDatos)=>{
                    return({...prevDatos, honorarios_total:redondear(honorariosUnidad),
                                           comision_cat:comision,
                                            comisionCalculada:comision,
                                           superficie_total:value,
                                           descuentoCalculado:descuentoUnidad
                                  
               })})}

            }
            setIsModified(true);
            setErrors({ ...errors, tipoLote: false});
        }
  
};
    const actualizarValoradosDelProyecto =(nuevoTipoProyecto, valoradosActuales) =>{
        const nuevosValorados = nuevoTipoProyecto && nuevoTipoProyecto.valorados.length > 0 ? nuevoTipoProyecto.valorados : [];
         
        const valoradosToAdd = nuevosValorados.filter(nuevoValorado => {
                 
            const valoradoExistente = valoradosActuales.find(val => val.id_valorado === nuevoValorado.id);
            return !valoradoExistente
        }).map(newVal => ({id_valorado:newVal.id, descripcion:newVal.descripcion,nombre_visible:newVal.nombre_visible,
                            id_proyecto:datos.id, nrovalorado:"", activo:true}));
        const valoradosUpdated = valoradosActuales.map(valoradoActual => {
            const existeEnNuevos = nuevosValorados.some(nuevoValorado => nuevoValorado.id === valoradoActual.id_valorado);
             return existeEnNuevos ?  { ...valoradoActual, activo: true } : { ...valoradoActual, activo: false };
        });

        return [...valoradosUpdated, ...valoradosToAdd]
    }

      const handleInputChange = async(name, value) => {

        if (name === "id_tipo_proyecto" && value !== datos.id_tipo_proyecto) {
            //Si cambia el tipo de proyecto...
            const tipoProyecto = tiposProyecto.find(tp => `${tp.id}` === `${value}`);
            
            let mensaje=null, result={isConfirmed:true}
            if(datos.unidades.length > 0){
                mensaje = "Se eliminarán las unidades registradas."
            } else if(parseFloat(datos.superficie_total) > 0){ 
                mensaje =  "Se reiniciará el cálculo de honorarios."
            }
            if(mensaje && (tipoProyecto?.alias ==="lote" || datos.tipo?.alias === "lote"))
            {
                result = await Swal.fire({
                    title: '¿Estás seguro?',
                    html: mensaje, // Usa HTML aq
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#464646',
                    confirmButtonText: 'Sí, continuar',
                    cancelButtonText: 'Cancelar'
                    })
            }
            if(result.isConfirmed){
                setDatos((prevDatos) => {
                    const valoradosActuales = prevDatos.valorados;
                    // Mapear sobre los nuevos valorados, copiando los datos de los existentes si hay coincidencias de id
                    const valoradosActualizados = actualizarValoradosDelProyecto(tipoProyecto, valoradosActuales)
                    
                    let datosBasicos, datosCalculo={};
                    let unidades=prevDatos.unidades;
                    if(prevDatos.tipo?.alias === "lote" || tipoProyecto?.alias === "lote") 
                    { datosBasicos={...initialDetalles, superficie_aprobada:"", incluyeplanoesquematico:false} 
                        unidades=[]
                        setUnidadSelected(initialUnidad)
                        setEditIndex(null)
                        setDatosCalculoGeneral(initialDatosCalculo)
                        setRequiereCalcular(false)
                        setIsCheckedEspecial(false)
                        setIncluyePlanoEsq(false)
                        setRequiereUnidades(false)
                        if(prevDatos.tipo?.alias === "lote")
                        { setDatosCalculoGeneral(prev=>({...prev, id_tipologia:tipoProyecto.tipologiaDefecto?.id
                            , id_categoria:tipoProyecto.tipologiaDefecto?.id_categoria
                            , id_area:tipoProyecto.tipologiaDefecto?.id_area,
                        ...datosCalculo}))}
                    }
                        else{
                            
                            if(prevDatos.tipo?.alias === "urbanizacion" || tipoProyecto?.alias === "urbanizacion" ||
                                prevDatos.tipo?.alias === "viviendasocial" || tipoProyecto?.alias === "viviendasocial"
                                ){
                                    const { errorViviendaPropia, conceptoDescuento, descuento } = handleChangeDescuento("viviendapropia", false)
                                    datosBasicos ={
                                        conceptoDescuento,
                                        descuento
                                    }
                                } else if(prevDatos.tipo?.alias === "ampliacion" || tipoProyecto?.alias === "ampliacion" ||
                                    prevDatos.tipo?.alias === "levantamiento" || tipoProyecto?.alias === "levantamiento"
                            ) { datosCalculo ={
                                superficie_aprobada:""
                            }} else datosBasicos={};
                        setDatosCalculoGeneral(prev=>({...prev, id_tipologia:tipoProyecto.tipologiaDefecto?.id
                            , id_categoria:tipoProyecto.tipologiaDefecto?.id_categoria
                            , id_area:tipoProyecto.tipologiaDefecto?.id_area,
                        ...datosCalculo}))
                        if (prevDatos.comision_cat> 0 || prevDatos.honorarios_total> 0)setTipoChanged(true)
                       
                    }
                    
                   
                    return { ...prevDatos, ...datosBasicos,...datosCalculo, unidades,valorados: valoradosActualizados, [name]: value, tipo:tipoProyecto };
                });}
        
        }
        else{
            setDatos((prevDatos) => ({...prevDatos, [name]: value }));
        }
        setIsModified(true);
        setErrors({ ...errors, [name]: value === '' });
        setErrorUnidadForm("")
        setErrorDatosCalculoForm("")
    };

    const handleInputDatosCalculo = async(name, value) => {
        console.log("handleInputDatosCalculo",name,value)
        let enviarACalcular=true
        let errorss={}
        if(name === "id_tipologia") 
        { const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${value}`);
            setDatosCalculoGeneral((prevDatos) => ({ ...prevDatos, [name]: value,
                                        tipologia:selectedTipologia,
                                        id_categoria: selectedTipologia?.categoria?.id ||"",
                                        id_area: selectedTipologia?.area?.id ||"",
            }))
        }
        else if(name === "id_categoria")
           { if(value === "") {
          
            setDatosCalculoGeneral((prevDatos) => ({ ...prevDatos, [name]: value,
                                                tipologia:null,
                                                id_tipologia: "",
                                                id_area: "",
                                                costo_mes:null
             }))
            }else{
                const selectedCategoria= categorias.find(cat => `${cat.id}` === `${value}`);
                setDatosCalculoGeneral((prevDatos) => ({ ...prevDatos, [name]: value,
                    id_tipologia: "",
                    id_area: "",
                    costo_mes:selectedCategoria?.costo_mes
    }))
        }}
        else if(name === "id_area" && value === "") {
            setDatosCalculoGeneral((prevDatos) => ({ ...prevDatos, [name]: value,
                                                id_tipologia: ""
             }))
        }
        else if (name === "superficie" || name === "superficie_aprobada") {
            if (value === "") {
                // Permitir vacío y actualizar el estado
                setDatosCalculoGeneral((prevDatos) => ({ ...prevDatos, [name]: value }));
                if (name === "superficie_aprobada") {
                    setDatos((prevDatos) => ({ ...prevDatos, [name]: value }));
                }
            } else {
                let superficieCantidad = positiveOrZero(value);
        
                // Validar que no tenga más de 2 decimales
                if (superficieCantidad >= 0) {
                    const partes = value.split(".");
                    if (partes.length === 2 && partes[1].length > 2) {
                        superficieCantidad = parseFloat(superficieCantidad.toFixed(2)); // Truncar a 2 decimales
                    }
        
                    setDatosCalculoGeneral((prevDatos) => ({ ...prevDatos, [name]: superficieCantidad }));
        
                    if (name === "superficie_aprobada") {
                        enviarACalcular = false;
                       
                        setDatos((prevDatos) => ({ ...prevDatos, [name]: superficieCantidad }));
                    }
                }
            }
        }else{ setDatosCalculoGeneral((prevDatos) => ({ ...prevDatos, [name]: value }));}

        setRequiereCalcular((prevReq)=>prevReq || enviarACalcular)
        setErrors(prevErrors => ({ ...prevErrors,
            calcularHonorarios:false,
            ...errorss,
            superficies:false }));
    };


    const handleChangeValorado = (index, value) => {
        setDatos(prevDatos => {
            const valorados = prevDatos.valorados.map((val) =>
                val.id_valorado === index ? { ...val, nrovalorado: value } : val
            );
            return { ...prevDatos, valorados }; // Actualiza el estado con la nueva copia de 'valorados'
        });
        setErrors(prev => ({...prev, valorados:false }))
        setIsModified(true);
    };

    const handleSelect = (name, object, noRecalcular = null) => {
    //Para manejar la seleccion de proyectista, inmueble o propietario
        const objetoId=object? object.id : ""
 
        if(name === 'proyectista') {
            if(objetoId !== ""){
            dispatch(fetchCuotasByAfiliado(objetoId));
            }else{
                setVerificaciones((prev)=> ({...prev, proyectistaAportes:null})) 
            }
           }
        setIsModified(true);

        let viviendaPropia = {
            errorViviendaPropia:false,
            conceptoDescuento:""
        }
        setDatos((prevDatos) =>{ 
            if(prevDatos.conceptoDescuento ==="viviendapropia" && name === "proyectista") {
                viviendaPropia = handleChangeDescuento("viviendapropia", true, object, prevDatos.propietarios)
            }   
        //VERIFICAR PROPIETARIO EN VIVIENDA PROPIA 
        return { ...prevDatos,
                    conceptoDescuento: viviendaPropia.conceptoDescuento,
                    [`id_${name}`]: objetoId, 
                    [name]:object 
                }
    });
        if(object) setErrors((prev)=>({...prev, [name]:false,
                                        viviendaPropia:viviendaPropia.errorViviendaPropia
                                    }));
    };


    const esUnidadValida = () => {
       if(unidadSelected.id_tipologia === "") return "Debe seleccionar una tipología.";
       if(unidadSelected.descripcion === "") return "Debe indicar un nombre para la unidad.";
       if(unidadSelected.num_unidades === "" || parseInt(unidadSelected.num_unidades) <= 0) return "La cantidad de unidades debe ser un número mayor a 0.";
       if(positiveOrZero(unidadSelected.superficie) <= 0) return "La superficie debe ser mayor a 0.";
       return "";
    };
    
//     const handleAddUniqueUnidad = () => {
//         let mensajeError
//         if(unidadSelected.id_tipologia === "") mensajeError = "Debe seleccionar una tipología.";
//         if(parseFloat(unidadSelected.superficie) <= 0 || unidadSelected.superficie === "") mensajeError = "La superficie debe ser mayor a 0.";   
//         if(mensajeError && mensajeError !== "") setErrorUnidadForm(mensajeError)
//        else { 
//         const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${unidadSelected.id_tipologia}`);
//         const selectedCategoria = categorias.find(cat => `${cat.id}` === `${selectedTipologia.id_categoria}`);
//         const indiceCalculo = obtenerIndiceCalculo(unidadSelected.superficie, selectedCategoria);

//          if(!indiceCalculo) {setErrorUnidadForm("No existe un indice de calculo para esa superficie, ingrese una superficie válida.")}
//         else {
//         setErrorUnidadForm("")
//         setErrors({ ...errors, unidades: false });
//         const {honorariosPrimera, honorariosUnidad, comisionPorUnidad} = calculoHonorariosUnidad(indiceCalculo, selectedCategoria, unidadSelected.num_unidades,unidadSelected.superficie);
//         const superficieTotal = parseFloat(unidadSelected.superficie)
//         let comision = redondear(comisionPorUnidad)
//         setDatos((prevDatos)=>{
//             const nuevaListaUnidades = [{...unidadSelected, honorariosPrimera, aporteVisacion:comisionPorUnidad, indice_calculo:indiceCalculo, honorarios:honorariosUnidad,tipologia:selectedTipologia, descripcion:"Unidad única"}]
//             return({...prevDatos, honorarios_total:redondear(honorariosUnidad),
//                                     comision_cat:comision,
//                                     comisionCalculada:comision,
//                                     superficie_total:superficieTotal,
//                                     unidades:nuevaListaUnidades
//         })})
//         setUnidadSelected((prevDatos)=>({...prevDatos, indice_calculo:indiceCalculo, costo_mes: selectedCategoria.costo_mes}))
//         setIsModified(true);  
//         setRequiereCalcular(false) 
//         setErrors({ ...errors, calcularHonorarios: false });
//         }
//    } };
    const handleAddUnidad = () => {
        const mensajeError = esUnidadValida();
        if(mensajeError !== "") setErrorUnidadForm(mensajeError)
       else { 
        const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${unidadSelected.id_tipologia}`);
        const selectedCategoria = categorias.find(cat => `${cat.id}` === `${selectedTipologia.id_categoria}`);
        // const indiceCalculo = obtenerIndiceCalculo(unidadSelected.superficie, selectedCategoria);
        const indiceCalculo = datosCalculoGeneral.indice_calculo;
        if(!indiceCalculo) {setErrorUnidadForm("No existe un indice de calculo para esa superficie, ingrese una superficie válida.")}
        else{
        setErrors({ ...errors, unidades: false });
        const nombreExistente = datos.unidades.find(uni => uni.descripcion?.toUpperCase() === unidadSelected.descripcion?.toUpperCase());
        if (!nombreExistente) {
            setErrorUnidadForm("")
        const nuevaListaUnidades = [...datos.unidades, {...unidadSelected,
            tipologia:selectedTipologia,
        costo_mes:selectedCategoria?.costo_mes}]
        recalcularHonorariosTotal(nuevaListaUnidades)        

        setIsModified(true);
        setUnidadSelected(prevState => ({ ...prevState, descripcion: "", num_unidades: "1"})); // Mantener valores
        }
        else{
            setErrorUnidadForm("Ya existe ese Nombre en la lista de unidades.")
        }
    
    }
   } };

   const obtenerIndiceCalculo  = (superficie, categoria) => {
     const indices = categoria.indicesCalculo

    const indiceEncontrado = indices.find(indice => {
        // Verificar si la superficie está dentro del rango del índice
        return parseInt(superficie) >= indice.superficie_min &&
               (indice.superficie_max === null || parseInt(superficie) <= indice.superficie_max);
    });
    // Devolver el índice de cálculo encontrado o un valor por defecto si no lo encuentra
    return indiceEncontrado ? indiceEncontrado.indice_calculo : 0;
};

const recalcularHonorariosTotal = (nuevaListaUnidades = null) => {
    let totalHonorarios = 0;
    let totalSuperficie = 0;
    let descuentoCalculado = 0;
    let comisionCalculada = 0;
    var errorsRecalcular={}
    let adicionAlCostoMes;
    if(datos.tipo?.alias === "lote"){
        handleTipoLoteChange("superficie_total", datos.superficie_total)
    } 
    else if(!requiereUnidades){
        console.log("datosCalculoGeneral en recalcular",datosCalculoGeneral)
        let mensajeError
        const superficieCantidad = parseFloat((positiveOrZero(datosCalculoGeneral.superficie).toFixed(2)))
        if(datosCalculoGeneral.id_tipologia === "") mensajeError = "Debe seleccionar una tipología.";
        if(superficieCantidad <= 0 && !esResellado) mensajeError = "La superficie debe ser mayor a 0.";

        if(mensajeError && mensajeError !== "") {
            setErrorDatosCalculoForm(mensajeError)
        }
        else {
        const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${datosCalculoGeneral.id_tipologia}`);
        const selectedCategoria = categorias.find(cat => `${cat.id}` === `${datosCalculoGeneral.id_categoria}`);
        const indiceCalculo = obtenerIndiceCalculo(superficieCantidad, selectedCategoria);
        if(!indiceCalculo) {  
            setErrorDatosCalculoForm("No existe un indice de calculo para esa superficie, ingrese una superficie válida."); 
         }
        else{
            setErrorDatosCalculoForm("")
            errorsRecalcular={ ...errorsRecalcular, unidades: false };
            adicionAlCostoMes =  datos.tipo?.alias === "ampliacion" && superficieCantidad <= 80? 0.25: 0;
        setDatos(prevDatos => {
           
                const {honorariosUnidad:honorariosTotal, comisionPorUnidad:visacionTotal, descuentoUnidad:descuentoTotal} = calculoHonorariosUnidad(
                indiceCalculo,
                selectedCategoria,
                1,
                superficieCantidad,
                adicionAlCostoMes
            );
            console.log("honorariosTotal en recalcular",honorariosTotal,visacionTotal)
            setDatosCalculoGeneral(prevDatos => {
                return {
                    ...prevDatos,
                    indice_calculo:indiceCalculo,
                    costo_mes:selectedCategoria.costo_mes,
                };
            
            });
            
            return {
                ...prevDatos,
                indice_calculo:indiceCalculo,
                costo_mes:selectedCategoria.costo_mes,
                superficie_total:superficieCantidad,
                honorarios_total: redondear(honorariosTotal),
                comisionCalculada:redondear(visacionTotal),
                comision_cat: redondear(visacionTotal),
                id_tipologia:datosCalculoGeneral.id_tipologia,
                tipologia:selectedTipologia,
                descuentoCalculado:descuentoTotal
            };

    });

        setRequiereCalcular(false)
        errorsRecalcular = { ...errorsRecalcular, calcularHonorarios: false };
    }}}
    else{
        let mensajeError
        const superficieIntroducidaCantidad = parseFloat((positiveOrZero(datosCalculoGeneral.superficie).toFixed(2)))
        if(datosCalculoGeneral.id_tipologia === "") mensajeError = "Debe seleccionar una tipología.";
        if( superficieIntroducidaCantidad < 0 || datosCalculoGeneral.superficie ==="") mensajeError = "La superficie debe ser mayor a 0.";

        if(mensajeError && mensajeError !== "") {
            setErrorDatosCalculoForm(mensajeError)
        }
        else {
            const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${datosCalculoGeneral.id_tipologia}`);
            const selectedCategoria = categorias.find(cat => `${cat.id}` === `${datosCalculoGeneral.id_categoria}`);
            const indiceCalculo = obtenerIndiceCalculo(datosCalculoGeneral.superficie, selectedCategoria);
            if(!indiceCalculo) {  
                setErrorDatosCalculoForm("No existe un indice de calculo para esa superficie, ingrese una superficie válida."); 
             }
            else{
            setErrorDatosCalculoForm("")
            // Luego actualiza 'datos' basándose en 'total'
            setDatosCalculoGeneral(prevDatosCalc => {
                
                    setUnidadSelected(prevUnidad => {
                        return {
                            ...prevUnidad,
                            indice_calculo:indiceCalculo,
                            costo_mes:selectedCategoria.costo_mes,
                            id_categoria:prevDatosCalc.id_categoria,
                            id_area:prevDatosCalc.id_area,
                            id_tipologia:prevDatosCalc.id_tipologia,
                            tipologia:selectedTipologia,
                        };
                    
                    });
                return {
                    ...prevDatosCalc,
                    indice_calculo:indiceCalculo,
                    costo_mes:selectedCategoria.costo_mes,
                };
            
            });
            setDatos(prevDatos => {
        
                let unidadesRecal = Array.isArray(nuevaListaUnidades)? nuevaListaUnidades : prevDatos.unidades
              if(datos.tipo?.alias === "ampliacion"){  const totalSuperficieCalculada = unidadesRecal.reduce((acumulador, unidad) => acumulador + (unidad.superficie*unidad.num_unidades), 0);
                adicionAlCostoMes = totalSuperficieCalculada <= 80? 0.25: 0;
            }
                const unidadesRecalculadas = unidadesRecal?.map(unidad => {
                    
                    const {honorariosPrimera,honorariosUnidad, comisionPorUnidad, descuentoUnidad} = calculoHonorariosUnidad(
                        indiceCalculo,
                        unidad.tipologia?.categoria,
                        unidad.num_unidades,
                        unidad.superficie,
                        adicionAlCostoMes
                    );
                    totalHonorarios += honorariosUnidad;
                    totalSuperficie += (parseFloat(unidad.superficie)*unidad.num_unidades);
                    comisionCalculada += comisionPorUnidad
                    descuentoCalculado += descuentoUnidad
                    return {
                        ...unidad, 
                        descripcion:unidad.descripcion.toUpperCase(),
                        honorarios: honorariosUnidad,
                        honorariosPrimera,
                        aporteVisacion:comisionPorUnidad,
                        indice_calculo:indiceCalculo,
                        descuento:descuentoUnidad

                    };
                });
              
                return {
                    ...prevDatos,
                    superficie_total:totalSuperficie,
                    honorarios_total: redondear(totalHonorarios),
                    comisionCalculada:redondear(comisionCalculada),
                    comision_cat: redondear(comisionCalculada),
                    unidades:unidadesRecalculadas,
                    tipologia:selectedTipologia,
                    id_tipologia:selectedTipologia?.id,
                    indice_calculo:indiceCalculo,
                    costo_mes:selectedCategoria.costo_mes,
                    descuentoCalculado
                };
            });
            setRequiereCalcular(false)
            errorsRecalcular = { ...errorsRecalcular, calcularHonorarios: false };
        }}

    }
    setIsModified(true);  
    setErrors(prevErrors => ({ ...prevErrors, recalcularHonorarios: false,
        superficies:false,
        viviendaPropia:false,
        ...errorsRecalcular }));
    setTipoChanged(null)
};

const calculoHonorariosUnidad  = (indiceCalculo, categoria, unidades_repetidas, superficie, adicionAlCostoMes = 0) => {
    let total = 0;
    let totalVisacion = 0;
    let descuentoTotal=0;
    const tipoProyecto = datos.id_tipo_proyecto ? tiposProyecto.find(t=> `${t.id}` === `${datos.id_tipo_proyecto}`):null
    //console.log("tipoProyecto",tipoProyecto)
    const extra =  categoria.costo_mes * adicionAlCostoMes
    let honorariosPrimera = parseFloat((parseFloat(superficie) * parseFloat(indiceCalculo) * (parseFloat(categoria.costo_mes) + parseFloat(extra))).toFixed(2))
   if(incluyePlanoEsq) { honorariosPrimera += parseFloat((honorariosPrimera*0.25).toFixed(2))}
    //console.log("honorarioPrimeraUnidad",parseFloat(superficie),parseFloat(indiceCalculo),(parseFloat(categoria.costo_mes) + parseFloat(extra)), honorarioPrimeraUnidad)
    for (let u = 1; u <= unidades_repetidas; u++) {
        const honorario = honorariosPorUnidadRepetida.find(h=> h.num_unidad_desde <=u && h.num_unidad_hasta >=u) || {porcentaje:2}
        // console.log(`Costo mensual: ${categoria.costo_mes}, Extra: ${extra}`); // Verifica si los valores son correctos
        const subtotal = parseFloat((honorariosPrimera * (honorario.porcentaje / 100)).toFixed(2));
        let descuento = datos.conceptoDescuento === "viviendapropia"? subtotal: 0
        // let subtotalConDescuento = subtotal- descuento
        total += subtotal; // Sumar el subtotal al total
        totalVisacion += parseFloat((subtotal*porcentaje_comision).toFixed(2))
        descuentoTotal += descuento
        // console.log("Unidad "+u+": subtotal: ",subtotal)
        // console.log("Datos de calculo ",superficie,indiceCalculo,parseFloat(categoria.costo_mes) + parseFloat(extra),honorario.porcentaje / 100)
    }
    return {honorariosPrimera:honorariosPrimera, 
            honorariosUnidad: total,
            comisionPorUnidad:totalVisacion,
            descuentoUnidad: descuentoTotal}
    }

    const handleEditUnidad = (index) => {
        setUnidadSelected(datos.unidades[index]);
        setErrorUnidadForm("")
        setEditIndex(index);
    };

    const handleSaveEdit = () => {
        const mensajeError = esUnidadValida();
        if(mensajeError !== "") setErrorUnidadForm(mensajeError)
       else { 
        const selectedTipologia = tipologias.find(tip => `${tip.id}` === `${unidadSelected.id_tipologia}`);
        const selectedCategoria = categorias.find(cat => `${cat.id}` === `${selectedTipologia.id_categoria}`);
        //const indiceCalculo = obtenerIndiceCalculo(unidadSelected.superficie, selectedCategoria);
        const indiceCalculo = datosCalculoGeneral.indice_calculo;
        if(!indiceCalculo) {setErrorUnidadForm("No existe un indice de calculo para esa superficie, ingrese una superficie válida.")}
        else{
            const nombreExistente = datos.unidades.find((uni, index) => index !== editIndex && uni.descripcion?.toUpperCase() === unidadSelected.descripcion?.toUpperCase());
        if (!nombreExistente) {
        setErrorUnidadForm("")
        setIsModified(true);
        const updatedUnidades = [...datos.unidades]; // Hacer una copia del array
        updatedUnidades[editIndex] = { ...unidadSelected,
            costo_mes:selectedCategoria?.costo_mes,
            tipologia: selectedTipologia 
        }; // Modificar el elemento específico
        recalcularHonorariosTotal(updatedUnidades)        

        setUnidadSelected(prevState => ({ ...prevState, descripcion: "", num_unidades: "1"})); // Mantener valores
        setEditIndex(null);
    }else{
        setErrorUnidadForm("Ya existe ese Nombre en la lista de unidades.")
    } // Limpiar la edición    
 
        setErrors(prev => ({...prev, formUnidad:false}))
    }     
  
    };}
    const redondear = (monto) => {
        return Math.round(monto);
    };
    const handleDeleteUnidad = (index) => {
        const updatedUnidades = datos.unidades.filter((_, i) => i !== index); // Eliminar la unidad en el índice específico
        recalcularHonorariosTotal(updatedUnidades)        

    };

    const handleFormat = e => {
        const { name, value } = e.target;
        const valueFormatted = name.includes('superficie') || name === 'indice_calculo' ? formatearDecimal(value) : value 
        setUnidadSelected((prevDatos) => ({ ...prevDatos, 
            [name]: valueFormatted
        }));
    };

    const handleListaPropietariosChange = (lista) => {
        setIsModified(true);
        
        let viviendaPropia = {
            errorViviendaPropia:false,
            conceptoDescuento:"",
        }
        setDatos((prevDatos) =>{ 
            if(prevDatos.conceptoDescuento ==="viviendapropia") {
                viviendaPropia = handleChangeDescuento("viviendapropia", true, prevDatos.proyectista, lista)
            }   
            return { ...prevDatos, 
                conceptoDescuento: viviendaPropia.conceptoDescuento,
                propietarios:lista 
            }});

        setErrors((prev)=>({...prev,
            viviendaPropia:viviendaPropia.errorViviendaPropia
        }));
    }
    const handlePlanoChange = (file, byCamera=false) => {
        handleFotoChange(file); 
        if(byCamera) clearFileInput()
        setIsModified(true);
        setErrors((prev)=>({...prev, plano:false}));
    }

    const areasFilteredGeneral = tipologias.filter(tipologia => `${tipologia.id_categoria}` === `${datosCalculoGeneral.id_categoria}`) // Filtra por categoría
                    .map(tipologia => tipologia.area); // Extrae el rubro

    // Si necesitas eliminar rubros duplicadas
    const uniqueAreasGeneral = areasFilteredGeneral.filter((area, index, self) =>
                    index === self.findIndex(a => a.id === area.id) // Compara el 'id' de cada rubro
                );
      
    const tipologiasFilteredGeneral = tipologias.filter(t=> (datosCalculoGeneral.id_categoria? `${t.id_categoria}`=== `${datosCalculoGeneral.id_categoria}` : true) &&
                                                    (datosCalculoGeneral.id_area? `${t.id_area}`=== `${datosCalculoGeneral.id_area}` : true))
   
  
    const areasFiltered = tipologias.filter(tipologia => `${tipologia.id_categoria}` === `${unidadSelected.id_categoria}`) // Filtra por categoría
                    .map(tipologia => tipologia.area); // Extrae el rubro

    // Si necesitas eliminar rubros duplicadas
    const uniqueAreas = areasFiltered.filter((area, index, self) =>
                    index === self.findIndex(a => a.id === area.id) // Compara el 'id' de cada rubro
                );
      
    const tipologiasFiltered = tipologias.filter(t=> (unidadSelected.id_categoria? `${t.id_categoria}`=== `${unidadSelected.id_categoria}` : true) &&
                                                    (unidadSelected.id_area? `${t.id_area}`=== `${unidadSelected.id_area}` : true))
    const erroreDatosProyecto = errors.comentario1 || errors.id_tipo_proyecto || errors.descripcion || errors.unidades || errors.recalcularHonorarios || errors.calcularHonorarios || errors.superficie || errors.superficies || errors.formUnidad
  
    const permitirPago = ((currentProyecto?.estado ==="pagado" || currentProyecto?.estado ==="aprobado") && rolActivo?.alias==="secretaria") || rolActivo?.alias ==="gerente"  
   
    const permitirCambios = !currentProyecto || (currentProyecto?.estado === "borrador" && tipoProceso!=="transferir") || (currentProyecto?.estado === "revision" && rolActivo?.alias === "gerente")

    const permitirCambiosRespaldos = permitirCambios || ((currentProyecto?.estado === "aprobado"||currentProyecto?.estado === "pagado") && rolActivo.alias!=="afiliado")
    
    const valoradosProyecto = datos.valorados?.filter(val=>val.activo && val.activo===true) || []
    console.log("form proyectosss",datos, currentProyecto)
    return (
        <>
        <Modal show={showModal} onHide={handleClose} backdrop="static" 
                keyboard={false} 
                size="xl"
                >
            <Modal.Header closeButton style={{ backgroundColor: formDetalles.headerColor, color: 'white' }}>
                <Modal.Title>{formDetalles.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}
                <Form className='normal-form'>
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                    justify
                    >
                <Tab 
                        eventKey="involucrados" 
                        title={
                            <>
                             {(errors.proyectista || errors.inmueble || errors.propietario|| (tipoProceso === "transferir" && errors.noCambioPropietario) ) ? (
                                    <span style={{ color: '#dc3444', marginLeft: '5px' }}>   Involucrados  <i className="bi bi-exclamation-circle"></i></span> 
                                ):(
                                    <>Involucrados</>
                                )}
                            </>
                        }
                    style={{padding:"0px 30px"}}
                    >
                     <TabInvolucrados 
                        handleSelect={handleSelect}
                        datos={datos}
                        tipoProceso={tipoProceso}
                        permitirCambios={permitirCambios}
                        esResellado={esResellado}
                        handleListaPropietariosChange={handleListaPropietariosChange}
                        errors={errors}
                        rolActivo={rolActivo}
                        verificaciones={verificaciones}
                        cuotas={cuotasFiltradas}
                        />
                    </Tab>
                 {(tipoProceso!=="transferir" && permitirCambios) &&  <Tab eventKey="datos_proyecto" title={
                            <>
                             {erroreDatosProyecto ? (
                                    <span style={{ color: '#dc3444', marginLeft: '5px' }}>   Detalles  <i className="bi bi-exclamation-circle"></i></span> 
                                ):(
                                    <>Detalles</>
                                )}
                            </>
                        }
                        style={{padding:"0px 20px"}}>

                       <TabDatosProyecto 
                                datos={datos}
                                datosCalculoGeneral={datosCalculoGeneral}
                                setDatos={setDatos}
                                errors={errors}
                                tiposProyecto={tiposProyecto}
                                categorias={categorias}
                                uniqueAreas={uniqueAreas}
                                uniqueAreasGeneral={uniqueAreasGeneral}
                                tipologiasFiltered={tipologiasFiltered}
                                tipologiasFilteredGeneral={tipologiasFilteredGeneral}
                                unidadSelected={unidadSelected}
                                editIndex={editIndex}
                                errorUnidadForm={errorUnidadForm}
                                errorDatosCalculoForm={errorDatosCalculoForm}
                                handleInputChange={handleInputChange}
                                handleInputDatosCalculo={handleInputDatosCalculo}
                                setUnidadSelected={setUnidadSelected}
                                handleAddUnidad={handleAddUnidad}
                                handleEditUnidad={handleEditUnidad}
                                handleSaveEdit={handleSaveEdit}
                                handleDeleteUnidad={handleDeleteUnidad}
                                recalcularHonorariosTotal={recalcularHonorariosTotal}
                                handleFormat={handleFormat}
                                tipoChanged={tipoChanged}
                                requiereCalcular={requiereCalcular}
                                setRequiereCalcular={setRequiereCalcular}
                                requiereUnidades={requiereUnidades}
                                incluyePlanoEsq={incluyePlanoEsq}
                                esLote={esLote}
                                handleCheckboxChange={handleCheckboxChange}
                                isCheckedEspecial={isCheckedEspecial}
                                handleTipoLoteChange={handleTipoLoteChange}
                                tiposEspecialesVisado={tiposEspecialesLote}
                                handleRequiereUnidades={handleRequiereUnidadesChange}
                                handleInluyePlanoEsq={handleCheckboxPlanoEsquematico}
                                tipoProceso={tipoProceso}
                                permitirCambios={permitirCambios}
                                handleChangeDescuento={handleChangeDescuento}
                                setErrors={setErrors}
                                esResellado={esResellado}
                                totalPago={totalPago}
                                currentProyecto={currentProyecto}
                                />
                    </Tab>}
             
                   {(tipoProceso==="transferir" || permitirCambiosRespaldos) && <Tab 
                        eventKey="Documentos" 
                        title={
                            <>
                            {(errors.documentos || errors.valorados || errors.plano) ? (
                                    <span style={{ color: '#dc3444', marginLeft: '5px' }}>   Respaldos  <i className="bi bi-exclamation-circle"></i></span> 
                                ):(
                                    <>Respaldos</>
                                )}
                               
                            </>
                        }
                        style={{padding:"0px 15px"}}
                    >
                         {(valoradosProyecto?.length >0) &&<> <Form.Label>VALORADOS</Form.Label>
                            <div className="rounded-2 mb-3" style={{border:"1px solid #dfdfdf", padding:"10px 15px"}}>
                     <Row className={`${errors.valorados ? 'border-danger' : ''}`}>
                            {valoradosProyecto?.length>0 ? 
                            <>
                            {valoradosProyecto.map((val,index)=> (
                                <Col md={4} lg={3} className="mb-3" key={`valorado_${index}`}>
                                <Form.Group>
                                    <Form.Label>{formatMayusculaCadaPalabra(val.nombre_visible || val.descripcion)} N°</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={`Número de ${(val.nombre_visible || val.descripcion)?.toLowerCase()}`}
                                        value={val.nrovalorado}
                                        onChange={(e)=>handleChangeValorado(val.id_valorado,e.target.value)}
                                        required
                                        disabled={!permitirCambiosRespaldos && tipoProceso!=="transferir"}
                                    />
                                </Form.Group>
                                </Col>
                            ))}
                            {errors.valorados && <div className="text-center text-danger">
                                    Todos los valorados utilizados en este proyecto deben estar registrados.
                            </div>}
                            </>: (
                                <div>
                                Debe seleccionar el tipo de proyecto para registrar los valorados correspondientes
                        </div>
                            )
                        }
                        </Row></div></>}
                        <Form.Label>CARIMBO DEL PLANO</Form.Label><br></br>
                        <div className="rounded-2 mb-3" style={{border:"1px solid #dfdfdf", padding:"10px 15px"}}>
                           <Row xs={1} md={1} lg={2} >

             <Col className='col-form'>
                
                 <Form.Control
                     type="file"
                     name="foto_plano"
                     isInvalid={!isFotoValid}
                     onChange={(e)=> handlePlanoChange(e.target.files[0])}
                     disabled={tipoProceso!=="transferir" && !permitirCambiosRespaldos}
                     ref={fileInputRef} 
                 />
                   <button
                        className="icon-button camara-button"
                        style={{ marginLeft: '10px' }}
                        onClick={() => startCamera(null)}
                         type="button"
                    >
                        <BsCamera />
                    </button>
                  <Form.Control.Feedback type="invalid">
                     Por favor selecciona una imagen (.png, .jpg, .jpeg) o documento (.pdf) válido.
                  </Form.Control.Feedback>
                  <div className="text-center min-height-block">
                         {fotoPreview && (
                             <div>
                                  <Form.Label>Foto Actual del Carimbo</Form.Label>
                                 <div className="position-relative">
                                 <VistaPreviaArchivo archivo={{url:fotoPreview, tipo:validationFn("doc/image", fotoPreview)}} /> 
                                   
                               </div>
                             </div>
                         )}
                     </div>
                  </Col>
                  <Col className='col-form'>
                 <div className="d-flex justify-content-center mb-4">
                     <div className="text-center">
                         {(fotoPlano && isFotoValid) && (
                             <>
                                 <Form.Label>Nueva Foto del Carimbo</Form.Label>
                                 <VistaPreviaArchivo archivo={{url:URL.createObjectURL(fotoPlano), tipo:validationFn("doc/image", fotoPlano)}} /> 
                             </>
                         )}
                     </div>
                 </div>
                 </Col>
                  </Row>
                  {errors.plano && (
                                <div className="text-center text-danger">
                                    Debe adjuntar la foto del carimbo del plano en formato imagen o pdf.
                                </div>
                                )}
                  </div>
                    </Tab>}
                  {(permitirPago || tipoProceso === "transferir") &&  <Tab 
                        eventKey="Pago" 
                        title={
                            <>
                            {(errors.totalPago || errors.depositos) ? (
                                    <span style={{ color: '#dc3444', marginLeft: '5px' }}>   Pago  <i className="bi bi-exclamation-circle"></i></span> 
                                ):(
                                    <>Pago</>
                                )}
                               
                            </>
                        }
                    >
                    <TabPago 
                           datos={datos}
                           esLote={esLote}
                           tipoProceso={tipoProceso}
                           permitirCambios={permitirCambios}
                           handleListaPropietariosChange={handleListaPropietariosChange}
                           errors={errors}
                           verificaciones={verificaciones}
                           cuotas={cuotas}
                            tipoPago={tipoPago}
                            depositos={depositos}
                            handleChangeDeposito={handleChangeDeposito}
                            paymentSaved={paymentSaved}
                            handleFileDepositoChange={handleFileDepositoChange}
                            startCamera={startCamera}
                            handleAddDeposito={handleAddDeposito}
                            handleRemoveDeposito={handleRemoveDeposito}
                            handleChangeTipoPago={handleChangeTipoPago}
                            esResellado={esResellado}
                            totalPago={totalPago}
                            handleProforma={handleProforma}
                           handleChangeDescuento={handleChangeDescuento}
                            setDatos={setDatos}
                            setErrors={setErrors}
                    />
                   </Tab>}
                   <Tab 
                                  eventKey="vistaGeneral" 
                                  title={
                                      <>
                                      {datos?.comentario2 ? (
                                              <span style={{ color: '#bc8600', marginLeft: '5px' }}>
                                                     Vista General  <i className="bi bi-exclamation-triangle"></i></span> 
                                          ):!errors.comentario2 ?(
                                              <>Vista General</>
                                          ): <span style={{ color: '#dc3444', marginLeft: '5px' }}>
                                                     Vista General  <i className="bi bi-exclamation-circle"></i></span> }
                                          
                                      </>
                                  }
                              >
                        <VistaGeneralProyecto proyecto={datos} 
                                            handleProforma={handleProforma} 
                                            nuevaFotoPlano={fotoPlano} 
                                            ver={false}
                                            errors={errors}
                                            handleInputChangeDatosProyecto={handleInputChange}/>
                   </Tab>
                </Tabs>

                    {requestStatus !== "sending" ? (<div className="list-container-header mt-4">
                        <div className="left-buttons">
                        {!currentProyecto && <button className="button-mine btn-cancel-outline" type="button" onClick={resetForm}>
                            Limpiar
                        </button>}
                        <button className="button-mine btn-cancel-outline" type="button" onClick={handleClose}>
                            Cancelar
                        </button>
                        </div>
                        <div className="right-buttons">
                        {tipoProceso!=="transferir" ?(<>
                          {tipoProceso!=="resellar" ? <>  {(!currentProyecto || tipoProceso==="duplicar" || (idUser===currentProyecto.id_usuario_creador && currentProyecto.estado === "borrador") )? 
                            <button className="button-mine btn-light-blue-outline" type="button" onClick={() => handleSubmit("borrador")} disabled={!isModified}>
                                Pre-Guardar
                            </button>:(<>{rolActivo?.alias === "gerente" && currentProyecto?.estado === "revision" && 
                                <button className="button-mine btn-light-blue-outline" type="button" onClick={() => handleSubmit("borrador")} disabled={!isModified}>
                                <i className="bi bi-arrow-return-left"></i> Devolver a pre-guardado
                            </button>}</>)
                            }
                            { rolActivo?.alias !== "gerente" && datos?.estado==="borrador"?
                                <button className="button-mine btn-presuccess-outline" type="button" onClick={() => handleSubmit("revision")} disabled={!isModified}>
                                    <i className="bi bi-check"></i>  Guardar para Revisión
                                </button>: rolActivo?.alias === "gerente" && currentProyecto?.estado === "aprobado" &&
                                <button className="button-mine btn-presuccess-outline" type="button" onClick={() => handleSubmit("revision")} disabled={!isModified}>
                                 <i className="bi bi-arrow-return-left"></i> Volver a revisión
                            </button>
                                }
                            {rolActivo?.alias === "gerente" && (currentProyecto?.estado !=="aprobado" &&currentProyecto?.estado !=="pagado") &&
                                <button className="button-mine btn-presuccess-outline" style={{border:"0px"}} type="button" onClick={() => handleSubmit("aprobado")} disabled={!isModified}>
                                    <i className="bi bi-check-circle"></i> Aprobar 
                                    {tipoProceso !== "resellar"? " Proyecto" : " Resellado"}
                                </button>
                            }
                             { (rolActivo?.alias === "gerente" && currentProyecto?.estado === "pagado" )&& 
                                <button className="button-mine  btn-money-outline" type="button" onClick={() => handleSubmit("aprobado")} disabled={!isModified}>
                                    <i className="bi bi-arrow-return-left"></i> Observar Pago
                                </button>
                            }
                            {(rolActivo?.alias === "gerente" || (rolActivo?.alias==="secretaria" &&(currentProyecto?.estado === "pagado" || currentProyecto?.estado === "aprobado") ))&& 
                                <button className="button-mine  btn-success-outline" type="button" onClick={() => handleSubmit("visado")} disabled={!isModified}>
                                    <i className="bi bi-check2-all"></i>  Visar {tipoProceso !== "resellar"? " Proyecto" : " Resellado"}
                                </button>
                            }
                            </> : 
                             <button className="button-mine btn-light-blue-outline" type="button" onClick={() => handleSubmit("borrador")} disabled={!isModified}>
                                Pre-Guardar Resellado
                            </button>
                            }
                      </> ):(<>
                            {/* { rolActivo?.alias !== "gerente" && datos?.estado==="borrador" && 
                                <button className="button-mine btn-presuccess-outline" type="button" onClick={() => handleSubmit("revision")} disabled={!isModified}>
                                    <i className="bi bi-check"></i>  Guardar para Revisión
                                </button>} */}
                             <button className="button-mine btn-presuccess-outline" style={{border:"0px"}} type="button" onClick={() => handleSubmit("visado")} disabled={!isModified}>
                             <i className="bi bi-check-circle"></i> Realizar Transferencia de propietario(s)
                         </button>
                         </>)}
                        </div>
                    </div>):
                    (
                                <div className="right-buttons">
                        <button className="button-mine btn-cancel-outline" type="button" disabled>
                          Enviando...
                           </button>
                    </div>
                    )}
                </Form>
            </Modal.Body>
               
      {showEpsonPrintModal && (
        <EpsonPrintModal
          show={showEpsonPrintModal}
          handleClose={() => setShowEpsonPrintModal(false)}
          content={proformaContent} 
        />
      )}
      
      {showCameraModal && (
        <Modal show={showCameraModal} onHide={() => setShowCameraModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Capturar Imagen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <video ref={videoRef} style={{ width: '100%' }} />
            <Form.Group className="mt-3">
              <Form.Label>Nombre del Archivo</Form.Label>
              <Form.Control
                type="text"
                value={nombreArchivo}
                onChange={(e) => setNombreArchivo(e.target.value)}
                placeholder="Ingrese el nombre del archivo"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <button className="button-mine"  type="button" onClick={() => setShowCameraModal(false)}>
              Cerrar
            </button>
            <button className="button-mine"  type="button" onClick={captureImage}>
              Capturar Imagen
            </button>
          </Modal.Footer>
        </Modal>
      )}
        </Modal>
     
      </>
    );
}


export default FormProyecto;
