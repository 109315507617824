import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Modal,
  ListGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { fetchValorados } from "../../reducers/valorado_slice";
import { modifyValoradosTipoProyecto } from "../../reducers/tipoProyecto_slice";
import Swal from "sweetalert2";

const FormValoradosTipoProyecto = ({ show, handleClose, tipo }) => {
  const dispatch = useDispatch();
  const valorados = useSelector((state) => state.valorado?.valorados);
  const [selectedValorados, setSelectedValorados] = useState([]);
  const currentUsuarioId = useSelector(state => state.sesion.id)
  const [availableValorados, setAvailableValorados] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");


//  console.log("selectedValorados", selectedValorados);
  useEffect(() => {
    if (tipo) {
      setSelectedValorados([...tipo.valorados]);
    }
    dispatch(fetchValorados());
  }, [dispatch, tipo]);

  useEffect(() => {
    if (tipo.valorados) {
      const available = valorados.filter(
        (valorado) =>{
          return !tipo.valorados.some(
            (valoradoU) =>
             {
              console.log("Rol y valoradou", valorado.id, valoradoU.id)
              return valoradoU.id === valorado.id}
          ) && valorado.alias !== "afiliado"}
      );
      setAvailableValorados(available);
    }
  }, [valorados, tipo]);

  const handleAddValorados = (valoradoToAdd) => {
      setSelectedValorados((prevSelected) => [
        ...prevSelected, valoradoToAdd,
      ]);
      setAvailableValorados((prevAvailableValorados) =>
        prevAvailableValorados.filter((valorado) => valorado.id !== valoradoToAdd.id)
      );
    
  };

  const handleRemoveValorados = (valoradoToRemove) => {
    setAvailableValorados((prevAvailableValorados) => [
      ...prevAvailableValorados,
      valoradoToRemove,
    ]);
    setSelectedValorados((prevSelected) =>{
      return prevSelected.filter((valorado) => valorado.id !== valoradoToRemove.id)}
    );
  };


  const handleSave = async () => {
    const valoradosToSend = selectedValorados.map((valorado) => valorado.id);
    const fd = new FormData();
    fd.append("valorados", JSON.stringify(valoradosToSend));

    dispatch(modifyValoradosTipoProyecto({id:tipo.id, valorados: fd})).then((res) => {
      console.log("res",res)
       if(res.error){
        Swal.fire('Error', res.payload?.messages[0] || 'Ocurrió un error al actualizar los valorados.', 'error');
       }
      else {Swal.fire('Actualizado', 'Se actualizaron los valorados correctamente.', 'success');
      handleClose();}
    }).catch((error) => {
      console.log("error",error)
      Swal.fire('Error', error.error || 'Ocurrió un error al actualizar los valorados.', 'error');
  });;
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredValorados = availableValorados.filter((valorado) =>
    (valorado.nombre_visible || valorado.descripcion).toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!tipo || !tipo.id) {
    return null;
  }
  const sortedValorados = [...selectedValorados].sort(
    (a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    }
  );

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton style={{ backgroundColor: "#255957", color: 'white' }}>
          <Modal.Title>
            Gestión de Valorados del tipo: {tipo.nombre}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mt-3">
                <div className="w-100">
                  <h5>Valorados Disponibles</h5>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Buscar por nombre"
                      aria-label="Buscar por nombre"
                      aria-describedby="basic-addon2"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </InputGroup>
                  <ListGroup style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {filteredValorados.map((valorado) => (
                      <ListGroup.Item key={valorado.id}>
                        <div className="d-flex justify-content-between">
                          {valorado.nombre_visible}
                          <button className="icon-button btn-secondary-outline" title="Asignar valorado" onClick={() => handleAddValorados(valorado)}>
                              <i className="bi bi-plus-lg"></i>
                          </button>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
              <div className="col-lg-6 mt-3">
                <div className="w-100">
                  <h5>Valorados asignados al tipo</h5>
                  <ListGroup style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {sortedValorados.map((valorado) => (
                      <ListGroup.Item key={valorado.id}>
                        <div className="d-flex justify-content-between">
                          {valorado.nombre_visible}
                         
                            {(currentUsuarioId === tipo.id? valorado.alias !== "admin" : true)&&
                           <button className="icon-button btn-secondary-outline" title="Quitar valorado" onClick={() => handleRemoveValorados(valorado)}>
                              <i className="bi bi-dash-lg"></i>
                          </button>
}
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="button-mine btn-cancel-outline" onClick={handleClose} type='button'>
              Cancelar
          </button>
          <button className="button-mine btn-success-outline" type="button" onClick={handleSave}>
            <i className="bi bi-check-circle"></i> Guardar
            </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FormValoradosTipoProyecto;
