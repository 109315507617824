import { Card, Col, Form, ListGroup, Row, Table } from "react-bootstrap"
import { formatNumber, apellidosNombreFullPersona, formatMayusculaCadaPalabra } from "../../util/customFormatter"
import { useEffect, useState } from "react";
import { fetchProyectoById, setCurrentProyecto } from "../../reducers/proyecto_slice";
import { useDispatch, useSelector } from "react-redux";
import { formatFecha, getUploadUrl } from "../../util/valueCalculator";
import imgPlaceholder from '../../assets/plano_placeholder2.png';
import CustomBadge from "./CustomBadge";
import VistaPreviaArchivo from "../UI/VistaPreviaArchivo";
import { validationFn } from "../../hooks/useInputFile";
import { fechaHoraBdAjustada } from "../recibo/ImprimirRecibo";
import { porcentaje_comision, positiveOrZero } from "./FormProyecto";
import CustomPrintModal from "../UI/CustomPrintModal";

const getConceptoReducido = (concepto)=>{
     if(concepto.toLowerCase().includes("resellado")) return "Resellado"
      if(concepto.toLowerCase().includes("transferencia")) return "Transferencia"
      if(concepto.toLowerCase().includes("visado")) return "Visado"
    return   ""
}

const VistaGeneralProyecto = ({proyecto, errors, handleInputChangeDatosProyecto,nuevaFotoPlano=null, ver=true, handleProforma=null}) => {
  const { rolActivo } = useSelector(state => state.sesion);
    const [proyectoDesplegado, setProyectoDesplegado] = useState(null);
    const honorariosPorUnidadRepetida = useSelector(state => state.unidad.honorariosUnidadRepetida || []);
    const [showPrintModal, setShowPrintModal] = useState(false);

    const handleOpenPrintModal = () => {
      setShowPrintModal(true);
    };
  
    const handleClosePrintModal = () => {
      setShowPrintModal(false);
    };
    const togglePropietarios = (id) => {
        setProyectoDesplegado((prev) => (prev === null? true : prev === id || prev === true? null : id));
      };
    if(!proyecto) return <></>

    const fotoPreview =  nuevaFotoPlano? URL.createObjectURL(nuevaFotoPlano) : 
    proyecto?.foto_plano ? getUploadUrl(proyecto.foto_plano) : 
                            imgPlaceholder
    const fotoFile =  nuevaFotoPlano? nuevaFotoPlano : 
                    proyecto?.foto_plano ? proyecto.foto_plano: 
                                            imgPlaceholder
    const valoradosProyecto = proyecto?.valorados?.filter(val=>val?.activo !== false)

    let idx=1;
    const unidadesData = proyecto.unidades.flatMap((u, index) => {
        let unidadesGroup = [];
    
        // Crear un arreglo con el número de unidades
        for (let i = 1; i <= u.num_unidades; i++) {
            console.log(i); // Este log imprimirá los números del 1 hasta num_unidades
            
            // Encontrar el honorario correspondiente para la unidad
            const honorario = honorariosPorUnidadRepetida.find(h => h.num_unidad_desde <= i && h.num_unidad_hasta >= i);
            const subtotal = parseFloat((u.honorariosPrimera * (honorario.porcentaje / 100)).toFixed(2));
            const subVisacion = parseFloat((subtotal * porcentaje_comision).toFixed(2));
            let nombre = `${u.descripcion}`
            if (u.num_unidades > 1) {
                nombre += ` N°${i} rep.`;
            }
            // Crear el detalle de la unidad como una fila de tabla (tr)
            let detalleUnidad = (
                <tr key={nombre}> {/* Aquí usamos 'i' como clave */}
                    <td>{idx}</td> {/* Muestra el índice + 1 para mostrar un valor numerado */}
                    {/* <td>
                        {`${u.tipologia?.categoria.nombre} CATEGORÍA `}
                        <span style={{ fontSize: "0.7rem" }}>
                            {`/ ${u.tipologia?.area.nombre} / ${u.tipologia?.descripcion}`}
                        </span>
                    </td> */}
                    <td>{nombre}</td>
                    <td className="text-center">{formatNumber(u.costo_mes)}</td>
                    <td className="text-center">{formatNumber(u.superficie)}</td>
                    <td className="text-center">{formatNumber(subtotal)}</td>
                    <td className="text-center">{formatNumber(subVisacion)}</td>
                </tr>
            
            );
    
            // Agregar la fila generada al grupo de unidades
            unidadesGroup.push(detalleUnidad);
            idx++
        }
    
        // Regresar el arreglo de filas (tr) para cada unidad
        return unidadesGroup;
    });
    
    // Ahora, `unidadesData` contiene las filas `<tr>` que puedes usar en tu HTML/JSX
    
    const pagoCasilla = proyecto.estado !== "visado" && proyecto.estado !== "anulado"?
                            (positiveOrZero(proyecto.monto_por_pagar)>=0 ? 
                                {etiqueta: "Monto por pagar:",
                                 monto:proyecto.monto_por_pagar
                                }:null
                            )
                            :(positiveOrZero(proyecto.pagado_en_la_fecha)>=0 ?
                            {   etiqueta: "Monto pagado:",
                                monto:proyecto.pagado_en_la_fecha
                               }:null)


    const supTotal = parseFloat(proyecto.superficie_aprobada||0) + parseFloat(proyecto.superficie_total||0) 
    
    return (
        <>
          <Row>
          {proyecto?.comentario2 && 
          <Row className="mb-4">
                <Col>
               
                <Card className="border-0 shadow-sm" style={{backgroundColor:"#ffdb82"}} >
                <Card.Body className="p-2">
                    <Card.Title key="cardObser" className="mb-2" style={{ fontSize: '1rem' }}>Observación de Gerencia:</Card.Title>
                    <ListGroup variant="flush">
                   
                                 <ListGroup.Item  style={{backgroundColor:"#ffdb82"}} className="p-1 d-flex">
                                 <div>{proyecto.comentario2}</div>
                             </ListGroup.Item>
                    </ListGroup>
                    </Card.Body>
                    </Card>    
        
                </Col>
            </Row> }
          {/* Columna izquierda: detalles del proyecto */}
          <Col md={7}>
       
          <Row className="mb-4">
                <Col>
                <Card className="border-0 shadow-sm">
                    <Card.Body className="p-2" style={{ fontSize: '0.9rem' }}>
                        <Card.Title className="mb-2" style={{ fontSize: '1rem' }}>Detalles del Proyecto</Card.Title>
                        <ListGroup variant="flush">
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Núm. de Visado:</div>
                                <div>{proyecto.num_visado}</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Estado:</div>
                                <div><CustomBadge estado={proyecto.estado}/></div>
                            </ListGroup.Item>
                           {proyecto.observacion && 
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Proceso especial:</div>
                                <div>{proyecto.observacion || "Visado"} </div>
                            </ListGroup.Item>}
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Observación:</div>
                                <div>{proyecto.comentario1 || "Ninguna"} </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Proyectista:</div>
                                <div>{proyecto.proyectista && apellidosNombreFullPersona(proyecto.proyectista.persona, "ci")}</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Propietario(s):</div>
                                <div>{proyecto.propietarios && proyecto.propietarios.length === 1 ? (
                                                      apellidosNombreFullPersona(proyecto.propietarios[0], "ci")
                                                    ) : 
                                                    proyecto.propietarios?.length >0 && (
                                                      <>
                                                        VARIOS{" "}
                                                        <button
                                                          onClick={() => togglePropietarios(proyecto.id)}
                                                          type="button"
                                                          className="btn-sm btn-transparent"
                                                          title="Ver propietarios"
                                                          style={{
                                                            display: "inline-flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              display: "inline-block",
                                                              transform: proyectoDesplegado ? "rotate(90deg)" : "rotate(0deg)",
                                                              transition: "transform 0.2s ease",
                                                            }}
                                                          >
                                                            <i className="bi bi-caret-right-fill"></i>
                                                          </span>
                                                        </button>
                                                      </>
                                                    )}
                                                    {proyectoDesplegado && (
                                                      <ol>
                                                        {proyecto.propietarios.map((propietario) => (
                                                          <li key={propietario.id}>{apellidosNombreFullPersona(propietario)}</li>
                                                        ))}
                                                      </ol>
                                                    )}
                                                    </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Cod Catastral:</div>
                                <div>{proyecto.inmueble?.cod_catastral}</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Zona:</div>
                                <div>{proyecto.inmueble?.zona?.nombre}</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Tipo de proyecto:</div>
                                <div>{proyecto.tipo?.nombre}</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Carácter:</div>
                                <div>{proyecto.descripcion}</div>
                            </ListGroup.Item>
                           { proyecto.tipologia  &&<ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Tipología:</div>
                                <div   style={{
                                            wordWrap: 'break-word', 
                                            overflowWrap: 'break-word', 
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                        }}>
                    {`${proyecto.tipologia.categoria?.nombre} CATEGORÍA/ ${proyecto.tipologia.area?.nombre}/ ${proyecto.tipologia.descripcion} (CM: ${formatNumber(proyecto.tipologia.categoria?.costo_mes)})`}</div>
                            </ListGroup.Item>}
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Superficie:</div>
                                <div>{formatNumber(proyecto.superficie_total)} m²</div>
                            </ListGroup.Item>
                        {proyecto.superficie_aprobada!==null &&proyecto.superficie_aprobada!==""  && <><ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Sup. aprob. prev.:</div>
                                <div>{formatNumber(proyecto.superficie_aprobada)} m²</div>
                            </ListGroup.Item>
                           <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Sup. total:</div>
                                <div>{formatNumber(supTotal)} m²</div>
                            </ListGroup.Item></>}
                            
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Honorarios:</div>
                                <div>{formatNumber(proyecto.honorarios_total)} Bs.</div>
                            </ListGroup.Item>
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Visación:</div>
                                <div>{formatNumber(proyecto.comision_cat)} Bs.</div>
                            </ListGroup.Item>
                     {pagoCasilla &&<ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>{pagoCasilla?.etiqueta}</div>
                                <div>{formatNumber(pagoCasilla?.monto)} Bs.</div>
                            </ListGroup.Item>}
                            {proyecto.conceptoDescuento === "viviendapropia" &&
                                <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Tipo descuento:</div>
                                <div>Vivienda propia</div>
                            </ListGroup.Item>
                            }
                            <ListGroup.Item className="p-1 d-flex">
                                <div style={{ minWidth: '145px', fontWeight: 'bold' }}>Plano Esquemático: </div>
                                <div>{proyecto.incluyeplanoesquematico? " Sí incluye" :" No incluye"}</div>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>

                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                {proyecto.tipo?.alias !== "lote" && proyecto.unidades?.length > 0  && (
                    <Card className="border-0 shadow-sm">
                <Card.Body className="p-2">
                    <Card.Title className="mb-2" style={{ fontSize: '0.95rem' }}>Unidades</Card.Title>
                    
                        <Table responsive bordered hover size="sm" className="mt-2" style={{ fontSize: '0.85rem' }}>
                            <thead className='text-center align-middle'>
                                <tr>
                                    <th>N°</th>
                                    <th>Nombre</th>
                                    <th>CM</th>
                                    <th>Superficie (m2)</th>
                                    <th>Honorarios (Bs)</th>
                                    <th>Aporte Visación (Bs)</th>
                                </tr>
                            </thead>
                            <tbody>
                            {unidadesData}
                            </tbody>
                        </Table>
                    

                </Card.Body>
            </Card>
 )}
                </Col>
            </Row>  
            <Row className="mb-4">
                <Col>
                <Card className="border-0 shadow-sm">
                <Card.Body className="p-2" style={{ fontSize: '0.9rem' }}>
                    <Card.Title className="mb-2" style={{ fontSize: '1rem' }}>Información Adicional</Card.Title>
                    <ListGroup variant="flush">
                    <ListGroup.Item className="p-1 d-flex">
                            <div style={{ minWidth: '150px', fontWeight: 'bold' }}>Registrado por :</div>
                            <div>{apellidosNombreFullPersona(proyecto.usuario?.persona, "ci")}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className="p-1 d-flex">
                            <div style={{ minWidth: '150px', fontWeight: 'bold' }}>Fecha de registro :</div>
                            <div>{fechaHoraBdAjustada(proyecto.fecha_registro).completa}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className="p-1 d-flex">
                        <div style={{ minWidth: '150px', fontWeight: 'bold' }}>Aprobado por : </div>
                            <div>{apellidosNombreFullPersona(proyecto.gerente, "ci")}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className="p-1 d-flex">
                        <div style={{ minWidth: '150px', fontWeight: 'bold' }}>Fecha de aprobación :</div>
                            <div>{fechaHoraBdAjustada(proyecto.fecha_aprobacion).completa}</div>
                        </ListGroup.Item>
                        {proyecto.usuarioAnulador &&   <ListGroup.Item className="p-1 d-flex">
                        <div style={{ minWidth: '150px', fontWeight: 'bold' }}>Anulado por : </div>
                            <div>{apellidosNombreFullPersona(proyecto.usuarioAnulador.persona, "ci")}</div>
                        </ListGroup.Item>}
                        
                    </ListGroup>
                </Card.Body>
            </Card>

                </Col>
            </Row>
 
          </Col>
          {/* Columna derecha: foto del plano */}
          <Col md={5}>
            {fotoPreview ? (
              <div className="text-center">
                <Card.Title style={{ fontSize: '1rem' }}>Carimbo del Plano</Card.Title>
                <VistaPreviaArchivo archivo={{url:fotoPreview, tipo:validationFn("doc/image", fotoFile)}} /> 
              
              </div>
            ) : (
              <div className="text-muted">No hay foto del plano disponible</div>
            )}

            <Row className="mt-4">
                <Col>
                {valoradosProyecto?.length>0 && 
                <Card className="border-0 shadow-sm">
                <Card.Body className="p-2" style={{ fontSize: '0.9rem' }}>
                    <Card.Title key="cardValorados" className="mb-2" style={{ fontSize: '1rem' }}>Valorados</Card.Title>
                    <ListGroup variant="flush">
                    {valoradosProyecto.map((val,index)=> (
                                 <ListGroup.Item key={val.id} className="p-1 d-flex">
                                 <div style={{minWidth:"80px",fontWeight: 'bold' }}>{formatMayusculaCadaPalabra(val.nombre_visible || val.descripcion)}: </div>
                                 <div> N° {val.ValoradoProyecto? val.ValoradoProyecto.nrovalorado : val.nrovalorado}</div>
                             </ListGroup.Item>
                            ))}
                    </ListGroup>
                    </Card.Body>
                    </Card>    
                }
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                {proyecto.recibos?.length>0  && 
                <Card className="border-0 shadow-sm">
                <Card.Body className="p-2" style={{ fontSize: '0.9rem' }}>
                    <Card.Title className="mb-2" style={{ fontSize: '1rem' }}>Recibos</Card.Title>
                    <ListGroup variant="flush">
                    {proyecto.recibos.map((recibo,index)=> (
                        <>
                               <ListGroup.Item key={recibo.id} className="p-2 d-flex flex-column flex-lg-row align-content-between">
                                    <div style={{ marginRight:"15px"}}>
                                    <span style={{ fontWeight: 'bold' }}>N°: </span> {recibo.nroRecibo}
                                    </div>
                                    <div style={{ marginRight:"15px"}}>
                                    <span style={{ fontWeight: 'bold' }}>Fecha: </span> {formatFecha(recibo.fecha)}
                                    </div>
                                    <div style={{ marginRight:"15px"}}>
                                    <span style={{ fontWeight: 'bold' }}>Monto: </span> {formatNumber(recibo.monto)} Bs.
                                    </div>
                                    <div>
                                    <span style={{ fontWeight: 'bold' }}>Concepto: </span> {getConceptoReducido(recibo.concepto)}
                                    </div>
                                </ListGroup.Item>

                  
                             </>
                            ))}
                    </ListGroup>
                    </Card.Body>
                    </Card>    
                }
                      <Row className="mt-4">
                    <Col>
                    {proyecto.proyectista && proyecto.estado!=="visado" && handleProforma && 
                        <button className="btn-sm btn-mine-tertiary"  type="button"  onClick={handleProforma} >
                            Proforma
                        </button>
                    }
                    </Col>
                </Row>
              {rolActivo?.alias==="gerente"&& (proyecto.estado !== "visado" && proyecto.estado!=="anulado"  && proyecto.estado!=="aprobado") && <Row>
                     <Col className="mt-4">
                        <Form.Group>
                                <Form.Label>Observación de Gerencia</Form.Label>
                                <Form.Control
                                    style={{backgroundColor:proyecto?.comentario2?"#ffdb82":"white"}}
                                    as="textarea"
                                    name="comentario2"
                                    placeholder="Observación"
                                    value={proyecto?.comentario2}
                                    onChange={(e) => handleInputChangeDatosProyecto(e.target.name, e.target.value)}
                                    disabled={ver || rolActivo?.alias!=="gerente"}
                                />
                            </Form.Group>
                            {errors?.comentario2 && <div className="text-danger">
                                   Debe devolver una Observación para retroceder el estado del proyecto
                            </div>}
                        </Col>
                        </Row>
                }
                </Col>
            </Row>
          </Col>
        </Row>
       
        </>
    )
}

export default VistaGeneralProyecto