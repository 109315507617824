import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchValoradoDets } from '../../reducers/valoradoDet_slice';
import { fetchValorados } from '../../reducers/valorado_slice'; 
import ValoradoDetForm from './ValoradoDetForm';
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx'; 
import CustomPrintModal from '../UI/CustomPrintModal';
import ActionButton from '../UI/Buttons/ActionButton';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../util/valueCalculator'; 
import { format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { es } from 'date-fns/locale';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { fetchAllCajasDiarias } from "../../reducers/caja_slice";


const ValoradoDetList = () => {
    const dispatch = useDispatch();
    const userId = useSelector(state => state.sesion.id);
    const { nombre, rolActivo, roles,id } = useSelector(state => state.sesion);
    const cajaState = useSelector(state => state.caja || { cajas: [] });
    const cajasDiarias = cajaState.cajasDiarias || [];
    const idCajaDiaria = useSelector(state => state.caja.idCajaDiaria);
    const valoradoDetState = useSelector(state => state.valoradoDet || { valoradoDets: [] });
    const valoradoDets = valoradoDetState.valoradoDets || [];
    const valorados = useSelector(state => state.valorado.valorados || []);
    
    const [showModal, setShowModal] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);  // Para manejar el formulario de nueva compra
    const [currentValoradoDet, setCurrentValoradoDet] = useState(null);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [printContent, setPrintContent] = useState({ head: [], body: [] });
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchValoradoDets());
        dispatch(fetchValorados());
        dispatch(fetchAllCajasDiarias()); // Llamar al thunk para cajas diarias
    }, [dispatch,id]);

    const handleViewDetail = (valoradoDet) => {
        setCurrentValoradoDet(valoradoDet);
        setShowModal(true);
    };

    const handleCreate = () => {
        setCurrentValoradoDet(null);
        setShowFormModal(true);  // Mostrar el modal para crear nueva compra
    };

    const handleCloseFormModal = () => {
        setShowFormModal(false);
        dispatch(fetchValoradoDets());  // Refrescar los datos al cerrar el formulario
    };

    const handleClose = () => {
        setShowModal(false);
        setCurrentValoradoDet(null);
    };

    const getValoradoDescripcion = (id_valorado) => {
        const valorado = valorados.find(v => v.id === id_valorado);
        return valorado ? (valorado.nombre_visible||valorado.descripcion) : 'Descripción no disponible';
    };

    const formatDateBolivia = (date) => {
        if (!date || isNaN(new Date(date).getTime())) {
            return "Fecha no válida";
        }
        const timeZone = 'America/La_Paz'; // Zona horaria de Bolivia
        return formatInTimeZone(date, timeZone, 'dd/MM/yyyy', { locale: es });
    };
    const sortedValoradoDets = useMemo(() => valoradoDets, [valoradoDets]);

    const handleExport = () => {
        const worksheet = XLSX.utils.json_to_sheet(buildExportData(sortedValoradoDets));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "ComprasValorados");
        XLSX.writeFile(workbook, "compras_valorados.xlsx");
    };

    const buildExportData = (data) => {
        return data.map(valoradoDet => ({
            Fecha: formatDateBolivia(valoradoDet.fecha),
            "Nombre Valorado": getValoradoDescripcion(valoradoDet.id_valorado),
            "Nro Recibo": valoradoDet.nroRecibo,
            Primero: valoradoDet.primero,
            Último: valoradoDet.ultimo,
            Costo: formatCurrency(valoradoDet.costo),
            "Precio Venta": formatCurrency(valoradoDet.precioventa),
            UltimoV: valoradoDet.ultimo_ven
        }));
    };

    const handleShowPreview = () => {
        setPrintContent(getPreviewContent());
        setShowPreviewModal(true);
    };

    const getPreviewContent = () => {
        const content = {
            head: [['Fecha', 'Nombre Valorado', 'Nro Recibo', 'Primero', 'Último', 'Costo', 'Precio Venta', 'Ult_vendido']],
            body: sortedValoradoDets.map(valoradoDet => [
                formatDateBolivia(valoradoDet.fecha),
                getValoradoDescripcion(valoradoDet.id_valorado),
                valoradoDet.nroRecibo || '',
                valoradoDet.primero || '',
                valoradoDet.ultimo || '',
                formatCurrency(valoradoDet.costo) || '',
                formatCurrency(valoradoDet.precioventa) || '',
                valoradoDet.ultimo_vendido || ''
            ])
        };
        return content;
    };

    return (
        <div className="list-container list0">
            <h1 className='table-title'>Lista de Compras de Valorados</h1>
            <div className="list-content">
                <div className='list-controls'>
                    <ActionButton onClick={handleCreate} outline icon='plus'
                    disabled={!idCajaDiaria}>
                        Nueva Compra de Valorado
                    </ActionButton>
                </div>
                <div className="list-container-header">
                    <div className="left-buttons">
                        <button title="Volver" onClick={() => navigate('/admin')} className="icon-button btn-transparent">
                            <i className="bi bi-arrow-left"></i>
                        </button>
                    </div>
                    <div className="right-buttons">
                        <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent">
                            <i className="bi bi-printer"></i>
                        </button>
                        <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent">
                            <i className="bi bi-file-spreadsheet"></i>
                        </button>
                    </div>
                </div>
                {valoradoDets.length === 0 ? (
                    <p>No hay compras de valorados disponibles.</p>
                ) : (
                    <div className='table-container'>
                        <Table bordered hover size='sm'>
                            <thead style={{ backgroundColor: "#b2aea3" }}>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Descripción Valorado</th>
                                    <th>Documento</th> {/* Añadimos el número de recibo */}
                                    <th>Primero</th>
                                    <th>Último</th>
                                    <th>Costo</th>
                                    <th>Precio Venta</th>
                                    <th>Ult_vendido</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedValoradoDets.map((valoradoDet) => (
                                    <tr key={valoradoDet.id}>
                                        <td>{formatDateBolivia(valoradoDet.fecha)}</td>
                                        <td>{getValoradoDescripcion(valoradoDet.id_valorado)}</td>
                                        <td>{valoradoDet.nroRecibo}</td> {/* Mostrar el número de recibo */}
                                        <td>{valoradoDet.primero}</td>
                                        <td>{valoradoDet.ultimo}</td>
                                        <td>{formatCurrency(valoradoDet.costo)}</td>
                                        <td>{formatCurrency(valoradoDet.precioventa)}</td>
                                        <td>{valoradoDet.ultimo_ven}</td>
                                        <td>
                                            <button className="icon-button btn-secondary-outline" title="Ver Detalle" onClick={() => handleViewDetail(valoradoDet)}>
                                                <i className="bi bi-search"></i> {/* Ícono de lupa para ver detalle */}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
                
                {/* Modal para crear una nueva compra de valorado */}
                <ValoradoDetForm showModal={showFormModal} handleClose={handleCloseFormModal} />

                {/* Modal para ver los detalles */}
                <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Detalle de la Compra de Valorado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {currentValoradoDet && (
                            <div>
                            <p><strong>Fecha:</strong> {formatDateBolivia(currentValoradoDet.fecha)}</p>
                            <p><strong>Nombre Valorado:</strong> {getValoradoDescripcion(currentValoradoDet.id_valorado)}</p>
                            {currentValoradoDet.nroRecibo.startsWith('E') ? (
                                <p><strong>Nro Recibo:</strong> {currentValoradoDet.nroRecibo}</p>
                            ) : (
                                <p><strong>Nro Comprobante:</strong> {currentValoradoDet.nroRecibo}</p>
                            )}
                            <p><strong>Primero:</strong> {currentValoradoDet.primero}</p>
                            <p><strong>Último:</strong> {currentValoradoDet.ultimo}</p>
                            <p><strong>Costo:</strong> {formatCurrency(currentValoradoDet.costo)}</p>
                            <p><strong>Precio Venta:</strong> {formatCurrency(currentValoradoDet.precioventa)}</p>
                            <p><strong>Estado:</strong> {currentValoradoDet.estado}</p>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Regresar</Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal de vista previa para imprimir */}
                <CustomPrintModal
                    show={showPreviewModal}
                    handleClose={() => setShowPreviewModal(false)}
                    title1="LISTA DE COMPRAS DE VALORADOS"
                    title2="POR CAJA"
                    content={printContent}
                    copia={false}
                />
            </div>
        </div>
    );
};

export default ValoradoDetList;
