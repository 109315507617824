import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchValorados, removeValorado } from '../../reducers/valorado_slice';
import ValoradoForm from './ValoradoForm';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import CustomPrintModal from '../UI/CustomPrintModal';
import ActionButton from '../UI/Buttons/ActionButton';

const ValoradoList = () => {
    const dispatch = useDispatch();
    const valoradoState = useSelector(state => state.valorado || { valorados: [] });
    const valorados = valoradoState.valorados || [];
    const [showModal, setShowModal] = useState(false);
    const [currentValorado, setCurrentValorado] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [printContent, setPrintContent] = useState({ head: [], body: [] });
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchValorados());
    }, [dispatch]);

    const handleDelete = id => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#464646',
            confirmButtonText: 'Sí, eliminarlo!',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeValorado(id)).unwrap().then(() => {
                    Swal.fire('¡Eliminado!', 'El valorado ha sido eliminado.', 'success');
                }).catch((error) => {
                    Swal.fire('Error', error.error || 'Ocurrió un error al eliminar el valorado', 'error');
                });
            }
        });
    };

    const handleSort = (key) => {
        if (sortConfig.direction === 'desc') {
            setSortConfig({ key: null, direction: 'asc' });
            return;
        }

        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
            direction = null;
        }
        setSortConfig({ key, direction });
    };

    const handleEdit = valorado => {
        setCurrentValorado(valorado);
        setShowModal(true);
    };

    const handleCreate = () => {
        setCurrentValorado(null);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setCurrentValorado(null);
    };

    const handleBack = () => {
        navigate('/admin');
    };

    const handleExport = () => {
        const worksheet = XLSX.utils.json_to_sheet(sortedValorados);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Valorados");
        XLSX.writeFile(workbook, "valorados.xlsx");
    };

    const handleShowPreview = () => {
        setPrintContent(getPreviewContent());
        setShowPreviewModal(true);
    };

    const handleClosePreview = () => {
        setShowPreviewModal(false);
    };

    const getPreviewContent = () => {
        const content = {
            head: [['Nombre', 'Código de Cuenta']],
            body: sortedValorados.map(valorado => [
                valorado.nombre_visible || valorado.descripcion,
                valorado.codigo_cuenta
            ])
        };
        return content;
    };

    const sortedValorados = useMemo(() => {
        let sortedData = [...valorados];
        if (sortConfig.key) {
            sortedData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedData;
    }, [valorados, sortConfig]);

    return (
        <div className="list-container list3">
            <h1 className='table-title'>Lista de Valorados</h1>
            <div className="list-content">
                <div className='list-controls'>
                    <div className="col-md-6">
                        <ActionButton onClick={handleCreate} outline icon='plus'>
                            Nuevo Valorado
                        </ActionButton>
                    </div>
                </div>
                <div className="list-container-header">
                    <div className="left-buttons">
                        <button title="Volver" onClick={handleBack} className="icon-button btn-transparent">
                            <i className="bi bi-arrow-left"></i>
                        </button>
                    </div>
                    <div className="right-buttons">
                        <button title="Imprimir" onClick={handleShowPreview} className="icon-button btn-transparent">
                            <i className="bi bi-printer"></i>
                        </button>
                        <button title="Exportar a excel" onDoubleClick={handleExport} className="icon-button btn-transparent">
                            <i className="bi bi-file-spreadsheet"></i>
                        </button>
                    </div>
                </div>
                {valorados.length === 0 ? (
                    <p>No hay valorados disponibles.</p>
                ) : (
                    <div className='table-container'>
                        <Table bordered hover size='sm'>
                            <thead style={{ backgroundColor: "#b2aea3" }}>
                                <tr>
                                    <th className='col-index'>N°</th>
                                    <th onClick={() => handleSort('descripcion')}>
                                        Nombre Visible
                                        {sortConfig.key === 'nombre_visible' && (
                                            sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                                        )}
                                    </th>
                                   
                                    <th onClick={() => handleSort('codigo_cuenta')}>
                                        Código de Cuenta
                                        {sortConfig.key === 'codigo_cuenta' && (
                                            sortConfig.direction === 'asc' ? <i className="bi bi-arrow-up"></i> : <i className="bi bi-arrow-down"></i>
                                        )}
                                    </th>
                                    <th className='col-simple-actions'>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedValorados.map((valorado, index) => (
                                    <tr key={valorado.id}>
                                        <td>{index + 1}</td>
                                        <td>{valorado.nombre_visible}</td>
                                        <td>{valorado.codigo_cuenta}</td>
                                        <td>
                                            <button className="icon-button btn-secondary-outline" title="Editar" onClick={() => handleEdit(valorado)}>
                                                <i className="bi bi-pencil"></i>
                                            </button>
                                            <button className="icon-button btn-secondary-outline" title="Eliminar" onClick={() => handleDelete(valorado.id)}>
                                                <i className="bi bi-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
                <ValoradoForm currentValorado={currentValorado} showModal={showModal} handleClose={handleClose} />
                <CustomPrintModal
                    show={showPreviewModal}
                    handleClose={handleClosePreview}
                    title1="LISTA DE VALORADOS"
                    title2=""
                    content={printContent}
                    copia={false}
                />
            </div>
        </div>
    );
};

export default ValoradoList;
